import { HashLink } from "react-router-hash-link"

export default function PageSiteEN() {
    return <>
        <section className="section">
            <h1>General conditions and legal information</h1>
            <p>Website Terms of Use <HashLink to="http://www.limpiointernational.com" smooth>http://www.limpiointernational.com</HashLink></p>
            <p>These general terms and conditions govern the relationship between Limpio International EOOD, hereinafter for short "Merchant", on the one hand, and the Users of Internet pages and services located on the website <HashLink to="http://www.limpiointernational.com" smooth>http://www.limpiointernational.com</HashLink>, hereinafter referred to as brevity users, on the other.</p>
            <p>Limpio International EOOD is a company registered under the Commercial Law of the Republic of Bulgaria with EIK 202898179 address, e-mail: info@limpiointernational.com, telephone: 0895738848</p>
            <p>Please read the published General Terms and Conditions in their entirety before using the information and commercial services offered on the Site (the Services for short).</p>
            <p>This document contains information about the activities of Limpio International Ltd. and the general terms and conditions for using the services provided by Limpio International Ltd., which govern the relationship between us and each of our users.</p>
            <p>The confirmation of the General Terms and Conditions is a necessary and mandatory condition for the conclusion of the contract between the User and the Merchant.</p>
            <p>By accepting the General Terms and Conditions, the User agrees to have his personal data processed on the basis of the contract concluded between him and the Merchant.</p>
            <h3>Definitions</h3>
            <p>For the purposes of these general conditions, the following concepts should be understood in the following sense:</p>
            <ul>
                <li>Site - <HashLink to="http://www.limpiointernational.com" smooth>http://www.limpiointernational.com</HashLink> and all its subpages.</li>
                <li>User - any natural person who acquires goods or uses services that are not intended for commercial or professional activity, and any natural person who, as a party to a contract under this law, acts outside the scope of his commercial or professional activity.</li>
                <li>General Terms and Conditions - these General Terms and Conditions, which include terms of use, cookies, registration and delivery rules, voluntary dispute resolution, contract cancellation and exchange forms and any other legally significant information found on the Site.</li>
                <li>Personal data - information about a natural person that reveals his physical, psychological, mental, family, economic, cultural or social identity.</li>
                <li>Goods - tangible movable property, with the exception of items sold under forced execution or through other measures by bodies authorized by law, as well as items abandoned or confiscated for the benefit of the state, announced for sale by state authorities. Goods are also water, gas and electricity when they are offered for sale, packaged in a limited volume or in a certain quantity.</li>
                <li>Sales contract - a contract under which the trader transfers or undertakes to transfer the ownership of goods to the consumer, and the consumer pays or undertakes to pay the price for them, including contracts having as their subject both goods and services.</li>
                <li>Service - any material or intellectual activity that is carried out in an independent way, is intended for another person and does not have as its main object the transfer of possession of an object.</li>
                <li>Service contract - a contract, other than a contract of sale, under which the trader provides or undertakes to provide a service to the consumer and the consumer pays or undertakes to pay the price for it.</li>
                <li>Procedure for alternative resolution of consumer disputes - procedure for out-of-court resolution of consumer disputes, meeting the requirements of this law and carried out by an authority for alternative resolution of consumer disputes.</li>
            </ul>
            <h3>Services provided</h3>
            <p>1. On the Site, Users have the opportunity to enter into contracts for service, purchase and sale and delivery of goods and services offered by the Merchant.</p>
            <h3>An order</h3>
            <p>2. Users use the website interface to conclude contracts with the Merchant for the goods and services offered.</p>
            <p>2.1. The contract for the purchase and sale of goods or services is considered concluded from the moment the order is confirmed by the Merchant</p>
            <p>2.2. In case of non-availability of a given product or inability to perform a given service, the Merchant reserves the right to refuse the order.</p>
            <p>2.3. After selecting one or more goods or services offered on the Merchant's website, the User must add the same to his list of goods or services for purchase.</p>
            <p>2.4. It is necessary for the User to provide data for making the delivery and choose a method and moment of payment of the price, then confirm the order through the site interface.</p>
            <p>2.5. When placing an order, the User receives confirmation by email that his order has been accepted.</p>
            <p>3. The Merchant has the right to refuse to conclude a contract with an incorrect User.</p>
            <p>3.1. The Merchant has the right to treat a User as incorrect in cases where:</p>
            <ol>
                <li>there is non-compliance by the User with the General Terms and Conditions;</li>
                <li>an incorrect, arrogant or rude attitude towards the Merchant's representatives has been established;</li>
                <li>systematic abuses by the User towards the Merchant have been established.</li>
            </ol>
            <h3>Prices</h3>
            <p>4. The prices of the goods or services offered are those indicated on the Merchant's website at the time of placing an order, except in cases of obvious error.</p>
            <p>4.1. The prices of the goods and services include VAT, in the cases in which it is foreseen to charge it.</p>
            <p>5. The merchant reserves the right to change the prices of the goods and services offered on the site at any time and without notice, such changes will not affect orders already placed.</p>
            <p>6. The Merchant may provide discounts for the goods and services offered on the site, in accordance with Bulgarian legislation and rules determined by the Merchant. The rules applicable to such discounts are available where the discount is displayed. Discounts may be provided in various forms (eg promotions, loyalty discounts provided individually, randomly or as a result of participation in a competition or customer survey).</p>
            <p>6.1. Different types of discounts cannot be combined when ordering and purchasing the same product/service.</p>
            <h3>Payment</h3>
            <p>7. When the User returns a product or service with the right to a refund of the amount paid for any reason, the price subject to a refund is reduced by the value of the received discount applied to the product or service, and only the amount actually paid is subject to a refund.</p>
            <p>8. The user can pay the price of the ordered goods/services by using one of the options listed on the website. On the Site, payment is possible through the following methods:</p>
            <ul>
                <li>Cash</li>
                <li>Cash on Delivery</li>
                <li>банков превод</li>
                <li>by credit or debit card</li>
            </ul>
            <p>9. If the User chooses the option of delivery by courier and payment by cash on delivery, he must pay the price of the ordered items together with the cost of delivery to the courier upon receipt of the goods.</p>
            <p>10. If the User chooses a payment method involving a third party payment service provider, the User may be bound by the terms and conditions and/or fees of such third party.</p>
            <p>11. The Merchant is not responsible if a given payment method involving a third-party payment service provider is unavailable or otherwise does not function due to reasons beyond the Merchant's fault.</p>
            <h3>Contract cancellation and replacement</h3>
            <p>12. The User has the right to withdraw from the contract without giving a reason, without owing compensation or a penalty within 14 days from the date of acceptance of the goods by the User or a third party, and in the case of a service contract - from the conclusion of the service contract.</p>
            <p>13. In order to exercise his right under this clause, the User must unequivocally notify the Merchant of his decision to withdraw from the contract, by individualizing the goods/services he wishes to return, by providing all the details of the completed order and delivery, including, but not limited to: content and value of the order, details of the person who placed the order, details of the person who accepted the delivery and date of delivery.</p>
            <p>14. The trader publishes on his website a form for exercising the right to withdraw from the contract.</p>
            <p>15. To exercise the right of withdrawal, the Merchant provides the user with the option to fill out and send electronically via the website the standard withdrawal form or another unambiguous application. In these cases, the Merchant immediately sends the user a confirmation of receipt of his refusal on a durable medium.</p>
            <p>16. The User is obliged to return the goods at his own expense, necessarily together with the receipt and the invoice, if any, by handing them over to the Trader or to an authorized person of the latter, within 14 days from the date on which the User exercised his right of withdrawal from the contract.</p>
            <p>17. When returning the product, it must be in its original packaging, without signs of use or damage to the commercial appearance.</p>
            <p>18. The Merchant has the right to delay refunding payments until the goods are received back or until proof is provided that the goods have been sent back, whichever occurs first.</p>
            <p>19. In the event that the User does not fulfill his obligation to return the product, without notifying the Trader of the delay and without providing a valid reason for the same, it is considered that he has withdrawn his statement to exercise the withdrawal from the contract.</p>
            <p>20. When, in connection with the execution of the contract, the Merchant has incurred expenses and the User withdraws from the contract, the Merchant has the right to retain the corresponding amount for the expenses incurred or to demand their payment.</p>
            <p>21. The user has no right to withdraw from the contract if the subject of the contract is:</p>
            <ol>
                <li>for the provision of services where the service is fully provided and its performance has begun with the express prior consent of the user and confirmation by him that he knows that he will lose his right of withdrawal after the contract has been fully performed by the trader.</li>
                <li>for the supply of goods or services, the price of which depends on the fluctuations of the financial market, which cannot be controlled by the trader and which may occur during the period for exercising the right of refusal, including for the supply of alcoholic beverages, the price of which is agreed at the conclusion of the sales contract, where the delivery can be made within a period not earlier than 30 days</li>
                <li>where the consumer has explicitly requested the trader to visit him at his home in order to carry out urgent repair or maintenance activities; where, during such a visit, the trader provides other services in addition to those requested by the consumer, or supplies goods other than the spare parts necessary to carry out the repair or maintenance, the right of refusal applies to those additional services or goods</li>
            </ol>
            <p>22. The Merchant reimburses the User for the price he paid for the returned goods.</p>
            <p>22.1. In the event that the user has made a payment under the contract with a bank card and has exercised his right to withdraw from the contract, the refund is carried out by ordering a reverse operation on the card with which the payment was made within 7 working days.</p>
            <h3>Warranties and claims</h3>
            <p>23. The user has the right to claim for any non-conformity of the goods or service with what was agreed/ordered, when inconsistencies with the sales contract are discovered after delivery.</p>
            <p>24. The trader is not responsible for the natural wear and tear of the goods.</p>
            <p>25. Any non-conformity of the consumer goods with the contract of sale, which appeared up to 6 months after the delivery of the goods, is considered to have existed at the time of its delivery, unless it is proved that the lack of conformity is due to the nature of the goods or the nature of the discrepancy.</p>
            <p>26. The consumer cannot dispute the conformity of the consumer goods with the contract for its sale, when:</p>
            <ol>
                <li>at the conclusion of the contract he knew or could not have been unaware of the non-conformity;</li>
                <li>the discrepancy is due to user-supplied materials.</li>
            </ol>
            <p>27. The user has the right to file a claim for the goods or service, regardless of whether the manufacturer or the trader has provided a commercial guarantee for the goods or service.</p>
            <p>28. When the satisfaction of the claim is carried out by replacing the goods with another corresponding to the agreed, the Merchant will retain the original warranty conditions for the consumer.</p>
            <p>29. When presenting the complaint, the user can claim a refund of the amount paid, to replace the product with another one corresponding to the agreed or to a deduction from the price.</p>
            <p>30. The complaint is submitted verbally to the telephone number specified by the Merchant or in writing via the specified e-mail, by post or delivered to the address of the company. The merchant provides access to a complaint form on his website.</p>
            <p>31. When submitting a complaint, the user indicates the subject of the complaint, his preferred way of satisfying the complaint, the amount of the claimed amount, and the address, phone number and email for contact.</p>
            <p>32. When submitting a complaint, the user must also attach the documents on which the claim is based, namely:</p>
            <ol>
                <li>receipt or invoice;</li>
                <li>protocols, acts or other documents establishing the non-compliance of the goods or services with the agreed;</li>
                <li>other documents establishing the claim by basis and amount.</li>
            </ol>
            <p>33. Complaints about consumer goods can be submitted up to two years from the delivery of the goods, but no later than two months from the establishment of non-compliance with the agreement. Complaints about services can be submitted up to 14 days from the discovery of the non-compliance of the service with the agreed upon.</p>
            <p>34. The term ceases to run during the time necessary to reach an agreement between the seller and the consumer to resolve the dispute.</p>
            <p>35. If the Merchant has provided a commercial guarantee for the goods and the term of the guarantee is longer than the terms for presenting the claim under para. 1, the complaint may be submitted until the expiry of the commercial guarantee.</p>
            <p>36. Filing a complaint is not an obstacle to filing a claim.</p>
            <p>37. The trader maintains a register of the claims submitted. The User is sent a document to the e-mail indicated by him, in which the number of the complaint from the register and the type of goods are indicated.</p>
            <p>38. When the Merchant satisfies the claim, he issues a deed to this effect, which is drawn up in two copies, and obligatorily provides one copy to the User</p>
            <p>39. In the event of a justified complaint, the trader brings the goods into compliance with the sales contract within one month, counted from the submission of the complaint by the User.</p>
            <p>39.1. If the goods are not repaired even after the expiration of the period under the previous paragraph, the User has the right to cancel the contract and to be reimbursed the amount paid or to request a reduction in the price of the consumer goods according to Art. 114 of the Civil Code.</p>
            <p>39.2. Bringing the consumer goods into compliance with the sales contract is free of charge for the Consumer. He does not owe the cost of shipping the consumer product or the materials and labor associated with its repair, and he does not suffer significant inconvenience.</p>
            <p>40. In case of non-compliance of the consumer goods with the sales contract and when the Consumer is not satisfied with the resolution of the complaint, he has the right to choose between one of the following options:</p>
            <ol>
                <li>cancellation of the contract and refund of the amount paid by him</li>
                <li>Price Reduction.</li>
            </ol>
            <p>41. The consumer cannot claim a refund of the amount paid or a reduction in the price of the goods when the merchant agrees to replace the consumer goods with a new one or to repair the goods within one month of the consumer's complaint.</p>
            <p>42. The trader is obliged to satisfy a request for cancellation of the contract and to refund the amount paid by the consumer, when, after having satisfied three complaints of the consumer by carrying out repairs of the same product, within the warranty period, there is a subsequent occurrence of non-compliance of the goods with the sales contract.</p>
            <p>43. The consumer cannot claim to cancel the contract if the non-conformity of the consumer goods with the contract is minor.</p>
            <h3>Intellectual Property</h3>
            <p>44. The intellectual property rights on all materials and resources located on the Merchant's website (including the available databases) are subject to protection under the Copyright Act and related rights, belong to the Merchant or to the corresponding designated person who assigned the right of use of the Merchant, and cannot be used in violation of current legislation.</p>
            <p>45. In the case of copying or reproduction of information beyond what is permissible, as well as in case of any other violation of intellectual property rights on the Merchant's resources, the Merchant has the right to claim compensation for direct and indirect damages suffered in full.</p>
            <p>46. Except in cases where it is expressly agreed, the User may not reproduce, change, delete, publish, distribute and publicize in any other way the information resources published on the Merchant's website.</p>
            <p>47. The Merchant undertakes to take due care to provide the User with an opportunity for normal access to the services provided.</p>
            <p>48. The merchant reserves the right to suspend access to the provided services. The Merchant has the right, but not the obligation, at its discretion to delete information resources and materials published on its site.</p>
            <h3>Termination and cancellation of the contract</h3>
            <p>49. The trader has the right at his discretion, without giving notice, to unilaterally terminate the contract, in case he finds that the services provided are used in violation of the present general conditions, the legislation in the Republic of Bulgaria and generally accepted moral norms.</p>
            <p>50. Apart from the cases provided for in these General Terms and Conditions, the contract between the parties is also terminated when the Merchant ceases its activities or ceases to maintain its website.</p>
            <p>51. Apart from the cases mentioned above, each of the parties may cancel this contract by giving a one-week notice to the other party in case of failure to fulfill the obligations under the contract.</p>
            <p>52. The written form of the contract is considered to be fulfilled by sending an e-mail message, pressing an electronic button on a page with content that is filled in or selected by the User or ticking a field (check box) on the website, etc. . similar, insofar as the statement is technically recorded in a way that makes it possible to reproduce it.</p>
            <h3>Rescue clause</h3>
            <p>53. The parties declare that, in the event that any of the clauses under these General Terms and Conditions is/are invalid, this will not invalidate the entire contract or any of its other parts. The invalid clause will be superseded by the mandatory rules of law or established practice.</p>
            <h3>Amendment of the general terms and conditions</h3>
            <p>54. The Merchant undertakes to notify the Users of any change in the present general conditions within 7 days of the occurrence of this circumstance at the e-mail address specified by the User.</p>
            <p>55. When he does not agree with the changes in the general conditions, the User has the right to withdraw from the contract without giving a reason and without owing compensation or penalty. In order to exercise this right, the User should notify the Merchant within one month of receiving the message under the previous article.</p>
            <p>56. In the event that the User does not exercise his right to withdraw from the contract in accordance with the procedure set out in these general conditions, it is considered that the amendment has been accepted by the User without objection</p>
            <h3>Applicable law</h3>
            <p>57. The provisions of the current legislation of the Republic of Bulgaria apply to all matters not settled by these General Terms and Conditions.</p>
        </section>
    </>
}
