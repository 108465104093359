import { useEffect, useState } from 'react'

import Language from './language'
import Burger from './burger'
import Primary from './primary'

const screen_lg = 1024

export default function Header() {
    const [ toggle, update_toggle ] = useState(false)
    const [ mode, update_mode ] = useState(window.innerWidth >= screen_lg ? `large` : `small`)

    const close = _ => update_toggle(false)

    useEffect(_ => {
        const resize = _ => {
            if (window.innerWidth >= screen_lg) {
                update_mode(`large`)
                update_toggle(false)
            } else {
                update_mode(`small`)
            }
        }
        window.addEventListener(`resize`, resize)
        return _ => window.removeEventListener(`resize`, resize)
    }, [ ])

    const content = <>
        <nav className="menu__item menu__secondary">
            <Language className="menu__secondary_item menu__language"/>
            { mode === `small` && <Burger className="menu__secondary_item menu__language" toggle={ toggle } update_toggle={ update_toggle }/> }
            { !(mode === `small`) && <Primary className={ `menu__secondary_item menu__language` } close={ close }/> }
        </nav>
        { (mode === `small` && toggle) && <Primary className={ `menu__primary menu__primary--${ mode }` } close={ close }/> }
    </>

    return <>
        <header id="header">
            <div className={ `menu menu--dynamic ${ mode === `large` ? `menu--large` : `menu--small` }` }>{ content }</div>
            <div className={ `menu menu--static ${ mode === `large` ? `menu--large` : `menu--small` }` }>{ content }</div>
        </header>
    </>
}
