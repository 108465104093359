import { useParams } from "react-router-dom"

export default function Page({ translation }) {
    const { language } = useParams()
    // TODO: come up with something better for undefined case
    return <>
        <main>
            { translation[ language ] === undefined ? <></> : translation[ language ] }
        </main>
    </>
}
