const default__state = { account: { id: null, validated: false, roleName: `ROLE_ANONYMOUS` } }

const logger = (state = default__state, action) => {
    switch (action.type) {
        case `LOGGER::LOG__ACCOUNT`:
            return { ...state, account: action.value.account }
        default:
            return { ...state }
    }
}

export default logger
