const default__state = { department__name: `all`, search__terms: `` }

const searcher = (state = default__state, action) => {
    switch (action.type) {
        case `SEARCHER::LOG__SEARCH`:
            return { ...state, department__name: action.value.department__name, search__terms: action.value.search__terms }
        default:
            return { ...state }
    }
}

export default searcher
