export default function PageDataEN() {
    return <>
        <section className="section">
            <h1 className="title_1">Personal data processing policy</h1>
            <h3 className="title_3">Information about the controller of personal data:</h3>
            <p className="paragraph">"Limpio International" EOOD, is a company registered in the Trade Register of The Registration Agency with UIC 202898179, with headquarters and management address: city Sofia, p.k. 1408, Triaditsa District, 16 Rozhenski Prohod Street, telephone: 0895738848; e-mail: info@limpiointernational.com.</p>
            <h3 className="title_3">Grounds and purposes for which we use your personal data</h3>
            <p className="paragraph">We process your personal data on the following grounds:</p>
            <ul className="list list--unordered">
                <li className="list__item list__item--unordered">A contract concluded between us and you in order to fulfill our obligations under it;</li>
                <li className="list__item list__item--unordered">Your express consent - the purpose is specified for each specific case;</li>
                <li className="list__item list__item--unordered">If required by law;</li>
            </ul>
            <p className="paragraph">In the following paragraphs you will find detailed information about the processing of your personal data depending on the basis on which we process it.</p>
            <h2 className="title_2">FOR THE PERFORMANCE OF A CONTRACT OR IN THE CONTEXT OF PRE-CONTRACTUAL RELATIONSHIPS</h2>
            <p className="paragraph">We process your personal data to fulfill contractual and pre-contractual obligations obligations and to enjoy the rights under the contracts concluded with you.</p>
            <h3 className="title_3">Purposes of processing:</h3>
            <ul className="list list--unordered">
                <li className="list__item list__item--unordered">establishing your identity;</li>
                <li className="list__item list__item--unordered">management and execution of your request and execution of a concluded contract;</li>
                <li className="list__item list__item--unordered">preparing a proposal for concluding a contract;</li>
                <li className="list__item list__item--unordered">preparing and sending a bill/invoice for the services you use with us;</li>
                <li className="list__item list__item--unordered">to provide you with the comprehensive service you need, as well as to collect the amounts due for the services used;</li>
                <li className="list__item list__item--unordered">saving correspondence in connection with orders placed, processing of requests, problem reporting, etc.</li>
                <li className="list__item list__item--unordered">notification of everything related to the services you use with us;</li>
                <li className="list__item list__item--unordered">analysis of customer history;</li>
                <li className="list__item list__item--unordered">detect and/or prevent illegal acts or acts in conflicts with our terms of service;</li>
            </ul>
            <h3 className="title_3">Data we process on this basis:</h3>
            <p className="paragraph">On the basis of the contract concluded between us and you, we process information about the type and the content of the contractual relationship, as well as any other information related to the contractual relationship, including:</p>
            <ul className="list list--unordered">
                <li className="list__item list__item--unordered">personal contact data - contact address, email, phone number;</li>
                <li className="list__item list__item--unordered">identification data - the three names, uniform civil number or personal alien number, permanent address;</li>
                <li className="list__item list__item--unordered">data on the orders placed;</li>
                <li className="list__item list__item--unordered">correspondence in connection with the overall service - e-mail, letters, information about your troubleshooting requests, complaints, requests, complaints, feedback we receive from you;</li>
                <li className="list__item list__item--unordered">credit or debit card, bank account number or other information banking and payment information in relation to payments made;</li>
            </ul>
            <h3 className="title_3">Other information such as:</h3>
            <ul className="list list--unordered">
                <li className="list__item list__item--unordered">Customer number, code or other identifier created for identification;</li>
                <li className="list__item list__item--unordered">Information from your actions on the site</li>
            </ul>
            <p className="paragraph">The processing of the specified personal data is mandatory for us in order to be able to conclude the contract with you and fulfill it. Without providing us with the above data, we would not be able to fulfill our contractual obligations.</p>
            <h3 className="title_3">We provide personal data to third parties</h3>
            <p className="paragraph">We provide your personal data to third parties, and our main purpose is to we offer quality, fast and comprehensive service. We do not provide your personal information third party data before ensuring that all technical and organizational measures to protect this data as we strive to implement strict control to fulfill this purpose. In this case, we remain responsible for the privacy and security of your data.</p>
            <h3 className="title_3">We provide personal data to the following categories of recipients (administrators of personal data):</h3>
            <ul className="list list--unordered">
                <li className="list__item list__item--unordered">postal operators and courier companies;</li>
                <li className="list__item list__item--unordered">persons assigned to maintain equipment, software and hardware used for processing personal data and necessary for the company&#39;s activities</li>
                <li className="list__item list__item--unordered">persons performing consulting services in various fields.</li>
            </ul>
            <h3 className="title_3">When we delete data collected on this basis</h3>
            <p className="paragraph">We delete the data collected on this basis 2 years after termination the contractual relationship, regardless of whether due to the expiration of the contract, cancellation or other reason.</p>
            <h2 className="title_2">TO FULFILL REGULATORY OBLIGATIONS</h2>
            <p className="paragraph">It is possible that the law stipulates an obligation for us to process your personal information data. In these cases, we are required to carry out the processing, such as:</p>
            <ul className="list list--unordered">
                <li className="list__item list__item--unordered">Obligations under the Anti-Money Laundering Measures Act;</li>
                <li className="list__item list__item--unordered">Fulfillment of obligations in connection with the distance sale, the sale outside the commercial premises, provided for in the Consumer Protection Act;</li>
                <li className="list__item list__item--unordered">Provision of information to the Consumer Protection Commission or third parties provided for in the Consumer Protection Act;</li>
                <li className="list__item list__item--unordered">Provision of information to the Commission for the Protection of Personal Data in in connection with obligations provided for in the regulations for the protection of personal data data;</li>
                <li className="list__item list__item--unordered">Obligations stipulated in the Accounting and Tax-Insurance Act procedural code and other related legal acts, in connection with the conduct of statutory accounting;</li>
                <li className="list__item list__item--unordered">Provision of information to the court and third parties, within the proceedings before a court, in accordance with the requirements of those applicable to the proceedings normative acts;</li>
                <li className="list__item list__item--unordered">Age verification when shopping online. When we delete personal data collected on this basis We delete the data collected pursuant to a legal obligation after the obligation to collect and store will be fulfilled or will cease to exist. For example:</li>
                <li className="list__item list__item--unordered">according to the Accounting Act on storage and processing of accounting data (11 years),</li>
                <li className="list__item list__item--unordered">obligations to provide information to the court, competent state authorities organs etc. grounds provided for in the current legislation (5 years).</li>
            </ul>
            <h3 className="title_3">Provision of data to 3rd parties</h3>
            <p className="paragraph">When we are required by law, we may provide yours personal data of the competent state authority, natural or legal person.</p>
            <h2 className="title_2">AFTER YOUR CONSENT</h2>
            <p className="paragraph">We only process your personal data on this basis after we have expressly, unequivocal and voluntary consent on your part. We will not predict any adverse consequences for you if you refuse to process personal data.</p>
            <p className="paragraph">Consent is a separate basis for processing your personal data and the purpose of processing is specified therein, and is not covered by the purposes listed in this policy. If give us the relevant consent and until its withdrawal or termination of any contractual relations with you, we prepare suitable proposals for you products/services by performing detailed analyzes of your basic personal data;</p>
            <p className="paragraph">Detailed analyzes is a method of performing an analysis that allows the processing of large volume of data through statistical models and algorithms and others that include use of personal data as well as pseudonymization processes and anonymizing the same, in order to extract information about trends and different statistical indicators.</p>
            <h3 className="title_3">Data we process on this basis:</h3>
            <p className="paragraph">On this basis, we only process the data for which you have given us your express consent consent. Specific data is determined on a case-by-case basis. Usually this data is names and phone number.</p>
            <h3 className="title_3">Provision of data to third parties</h3>
            <p className="paragraph">On this basis, we may provide your data to marketing agencies, Facebook, Google or similar.</p>
            <h3 className="title_3">Withdrawal of consent</h3>
            <p className="paragraph">Consents may be withdrawn at any time. Withdrawal of consent has no effect on the performance of contractual obligations. If withdraw your consent to the processing of personal data in any or all ways, described above, we will not use your personal data and information about the objectives defined above. Withdrawal of consent does not affect legality of processing based on consent prior to its withdrawal. To withdraw the given consent it is only necessary to use our site or just our contact details.</p>
            <h3 className="title_3">When we delete data collected on this basis</h3>
            <p className="paragraph">We delete the data collected on this basis upon your request or 12 months after their initial collection.</p>
            <h2 className="title_2">PROCESSING OF ANONYMIZED DATA</h2>
            <p className="paragraph">We process your data for static purposes, that is, for analyzes in which the results are only generalizable and therefore the data is anonymous. Identification of a specific person from this information is impossible. Your data can also be anonymized. Anonymization represents alternative to data deletion. When anonymized, all personally identifiable elements/ elements that allow you to be identified are irreversibly deleted. For anonymized data has no legal obligation to delete, as no constitute personal data.</p>
            <h3 className="title_3">Why and how we use automated algorithms</h3>
            <p className="paragraph">For the processing of your personal data, we use partially automated methods algorithms and methods in order to constantly improve our products and services for adapting our products and services to your needs in the best possible way. This process is called profiling.</p>
            <h3 className="title_3">How we protect your personal data</h3>
            <p className="paragraph">To ensure adequate data protection of the company and its customers, we we implement all the necessary organizational and technical measures provided for in the Law for the protection of personal data.</p>
            <p className="paragraph">The company has established rules to prevent abuses and breaches in security, which supports the processes of protecting and ensuring the security of Your data.</p>
            <p className="paragraph">For maximum security in the processing, transfer and storage of your data, it can to use additional security mechanisms such as encryption, pseudonymization and etc.</p>
            <h3 className="title_3">Personal data we have received from 3rd parties</h3>
            <p className="paragraph">We receive personal data from the following 3rd parties: Social networks</p>
            <h3 className="title_3">Rights of Users</h3>
            <p className="paragraph">Every User of the site enjoys all the rights to protect personal data according to the Bulgarian legislation and the law of the European Union. The user can exercise his rights through the contact form or by sending in a message to our email.</p>
            <p className="paragraph">Each User has the right to:</p>
            <ul className="list list--unordered">
                <li className="list__item list__item--unordered">Being informed (in connection with the processing of his personal data by the administrator);</li>
                <li className="list__item list__item--unordered">Access to your own personal data;</li>
                <li className="list__item list__item--unordered">Correction (if the data is inaccurate);</li>
                <li className="list__item list__item--unordered">Erasure of personal data (right "to be forgotten");</li>
                <li className="list__item list__item--unordered">Restriction of processing by the administrator or processor personal data;</li>
                <li className="list__item list__item--unordered">Portability of personal data between individual administrators;</li>
                <li className="list__item list__item--unordered">Objection to the processing of his personal data;</li>
                <li className="list__item list__item--unordered">The data subject also has the right not to be the subject of a decision based on solely of automated processing, including profiling, which gives rise to legal consequences for the data subject or similarly significantly affects;</li>
                <li className="list__item list__item--unordered">Right to judicial or administrative protection, in the event that the rights of data subjects have been breached. The user can request deletion if one of the following conditions is present:</li>
                <li className="list__item list__item--unordered">The personal data are no longer necessary for the purposes for which they were collected or otherwise processed;</li>
                <li className="list__item list__item--unordered">The user withdraws his consent on which the processing is based the data and there is no other legal basis for the processing;</li>
                <li className="list__item list__item--unordered">The data user objects to the processing and has no legal grounds for the processing, which have an advantage;</li>
                <li className="list__item list__item--unordered">Personal data has been processed unlawfully;</li>
                <li className="list__item list__item--unordered">Personal data must be deleted in order to comply with a legal obligation under the law of the Union or the law of a Member State which applies to the administrator;</li>
                <li className="list__item list__item--unordered">The personal data was collected in connection with the offer of services of the information society of children and consent is given by the bearer parental responsibility for the child. The user has the right to limit the processing of his personal data by the administrator when:</li>
                <li className="list__item list__item--unordered">Dispute the accuracy of personal data. In this case, the limitation of processing is for a period that allows the administrator to check the accuracy of personal data;</li>
                <li className="list__item list__item--unordered">The processing is illegal, but the User does not want the personal data to be deleted, but requires instead that their use be restricted;</li>
                <li className="list__item list__item--unordered">The administrator no longer needs the personal data for the purposes of the processing, but the User requires them for the establishment, exercise or the defense of legal claims;</li>
                <li className="list__item list__item--unordered">Object to processing pending verification of legality reasons of the administrator take precedence over the interests of the User.</li>
            </ul>
            <h3 className="title_3">Right of portability.</h3>
            <p className="paragraph">The data subject has the right to receive the personal data concerning him and who he is provided to the administrator, in a structured, widely used and suitable for machine readable format and has the right to transfer this data to another administrator without hindrance from the administrator to whom the personal data is provided, when the processing is based on consent or a contractual obligation and the processing is carried out in an automated manner. When exercising his right to data portability, the data subject has the right to obtain a direct transfer as well of personal data from one administrator to another when this is technical feasible.</p>
            <h3 className="title_3">Right to object.</h3>
            <p className="paragraph">Users have the right to object to the administrator against the processing of their personal data. The personal data administrator is obliged to terminate the processing, unless it proves that there are compelling legal grounds for the processing, which take precedence over the interests, rights and freedoms of the subject of data, or for the establishment, exercise or defense of legal claims. At objection to the processing of personal data for direct marketing purposes processing should cease immediately.</p>
            <h3 className="title_3">Complaint to the supervisory authority</h3>
            <p className="paragraph">Every User has the right to file a complaint against illegal processing of his personal data to the Personal Data Protection Commission or to the competent court.</p>
            <h3 className="title_3">Maintaining a register</h3>
            <p className="paragraph">We maintain a register of the processing activities for which we are responsible. This one register contains all the information below:</p>
            <ul className="list list--unordered">
                <li className="list__item list__item--unordered">The name and contact details of the administrator</li>
                <li className="list__item list__item--unordered">The purposes of processing;</li>
                <li className="list__item list__item--unordered">Description of categories of data subjects and categories of personal data;</li>
                <li className="list__item list__item--unordered">The categories of recipients to whom the personal data are or will be disclosed,</li>
                <li className="list__item list__item--unordered">Including recipients in third countries or international organizations;</li>
                <li className="list__item list__item--unordered">Where possible, the estimated deletion deadlines for the various categories data;</li>
                <li className="list__item list__item--unordered">Where possible, a general description of the technical and organizational</li>
            </ul>
        </section>
    </>
}
