import { useParams } from "react-router-dom"
import { HashLink } from 'react-router-hash-link'

const translate = {
    en: {
        home: `Home`,
        about: `About us`,
        boutique: `Boutique cleaning`,
        service: `Prices & Services`,
        contact: `Contacts`,
    },
    bg: {
        home: `Начало`,
        about: `За нас`,
        boutique: `Бутиково почистване`,
        service: `Цени & Услуги`,
        contact: `Контакти`,
    },
}

export default function MenuPrimary({
    className,
    close,
}) {
    const { language } = useParams()
    const translation = translate[ language ]
    const make_link = to => <HashLink className="menu__link" to={ `/${ language }/${ to }` } onClick={ close } smooth>{ translation[ to ] }</HashLink>
    return <>
        <nav className={ className }>
            { make_link(`home`) }
            { make_link(`about`) }
            { make_link(`boutique`) }
            { make_link(`service`) }
            { make_link(`contact`) }
        </nav>
    </>
}
