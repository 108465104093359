import { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'

import api from 'utility/api'

const translate = {
    en: {
        title: `Contact us`,
        first_name: `Name`,
        last_name: `Family name`,
        email: `Email`,
        phone: `Phone`,
        message: `Message`,
        send: `Send`,
    },
    bg: {
        title: `Свържете се с нас`,
        first_name: `Име`,
        last_name: `Фамилия`,
        email: `Имейл`,
        phone: `Телефон`,
        message: `Съобщение`,
        send: `Изпращане`,
    },
}


const form_empty = {
    first_name: ``,
    last_name: ``,
    email: ``,
    phone: ``,
    message: ``,
    success: false,
    errors: [ ],
}

export default function Contact() {
    const { language } = useParams()
    const translation = translate[ language ]
    const [ form, update_form ] = useState({ ...form_empty })

    const on_input = field => ({ target }) => update_form(current => ({ ...current, [ field ]: target.value }))

    const on_click = event => {
        event.preventDefault()
        update_form(current => ({ ...current, success: undefined }))
    }

    useEffect(_ => {
        const { success, ...payload } = form
        if (success !== undefined) {
            return
        }
        // console.log(`send`, form)
        
        api.post(`send`, {
            payload,
            resolve: ({ data }) => {
                update_form(data.success ? {
                        ...form_empty,
                        success: true,
                    } : current => ({
                        ...current,
                        success: false,
                        errors: data.errors,
                    })
                )
            },
        })
    }, [ form ])

    const make_input = identity => <>
        <div className="group_input">
            <label className="group_input__text" htmlFor={ identity }>{ translation[ identity ] } *</label>
            <input className="group_input__input" id={ identity } type="text" value={ form[ identity ] } onInput={ on_input(identity) }/>
        </div>
    </>

    const make_text = identity => <>
        <div className="group_input">
            <label className="group_input__text" htmlFor={ identity }>{ translation[ identity ] } *</label>
            <textarea className="group_input__input" id={ identity } type="text" value={ form[ identity ] } onInput={ on_input(identity) } rows={ 7 }></textarea>
        </div>
    </>

    return <>
        <section className="section">
            <h1 className="title">{ translation.title }</h1>
            {/* <img src="/file/image/contact-full.jpg" width={ 720 }/> */}

            {
                form.success
                    ? <p>Message sent!</p>
                    : form.success === undefined
                    ? <p>Loading...</p>
                    : (
                        <ul>
                            { form.errors.map(({ param, msg }) => <li key={ `contact_'${ param }'_'${ msg }'` }>- { param[ 0 ].toUpperCase() }{ param.slice(1) } { msg.toLowerCase() }</li>) }
                        </ul>
                    )
            }
            <form className="form">
                { make_input(`first_name`) }
                { make_input(`last_name`) }
                { make_input(`phone`) }
                { make_input(`email`) }
                { make_text(`message`) }
                <div className="group_button">
                    <button className="group_button__button" type="button" onClick={ on_click }>{ translation.send }</button>
                </div>
            </form>
        </section>
    </>
}
