import Email from 'component/email'
import Website from 'component/website'

export default function PageDataBG() {
    return <>
        <section className="section">
            <h1 className="title_1">Политика за защита на лични данни</h1>
            <h3 className="title_3">Информация за администратора на лични данни</h3>
            <p className="paragraph">„Лимпио Интернешънъл“ ЕООД, е дружество, регистрирано в Търговския регистър на Агенцията по вписванията с ЕИК <span className="fancy">202898179</span>, със седалище и адрес на управление: град гр. София, п.к. <span className="fancy">1408</span>, р-н Триадица, ул. Роженски проход <span className="fancy">16</span>, Тел: <span className="fancy">0895738848</span>; e-mail: <Email/>.</p>
            <h3 className="title_3">Основания и цели, за които използваме Вашите лични данни</h3>
            <p className="paragraph">Ние обработваме Вашите лични данни на следните основания:</p>
            <ul className="list list--unordered">
                <li className="list__item list__item--unordered">Сключения между нас и Вас договор с цел да изпълним задълженията си по него;</li>
                <li className="list__item list__item--unordered">Изрично съгласие от Вас – целта се посочва за всеки конкретен случай;</li>
                <li className="list__item list__item--unordered">При предвидено задължение по закон;</li>
            </ul>
            <p className="paragraph">В следващите параграфи ще намерите детайлна информация относно обработването на личните Ви данни в зависимост от основанието, на което ги обработваме.</p>
            <h2 className="title_2">ЗА ИЗПЪЛНЕНИЕ НА ДОГОВОР ИЛИ В КОНТЕКСТА НА ПРЕДДОГОВОРНИ ОТНОШЕНИЯ</h2>
            <p className="paragraph">Ние обработваме личните Ви данни, за да изпълняваме договорните и преддоговорни задължения и да се ползваме от правата по договорите, сключени с Вас.</p>
            <h3 className="title_3">Цели на обработката:</h3>
            <ul className="list list--unordered">
                <li className="list__item list__item--unordered">установяване на самоличността Ви;</li>
                <li className="list__item list__item--unordered">управление и изпълнение на Ваша заявка и изпълнение на сключен договор;</li>
                <li className="list__item list__item--unordered">изготвяне на предложение за сключване на договор;</li>
                <li className="list__item list__item--unordered">изготвяне и изпращане на сметка/фактура за услугите, които използвате при нас;</li>
                <li className="list__item list__item--unordered">за да осигурим необходимото Ви цялостно обслужване, както и да събираме дължимите суми за ползваните услуги;</li>
                <li className="list__item list__item--unordered">запазване на кореспонденция във връзка с направени поръчка, обработка на заявки, докладване на проблеми и др.</li>
                <li className="list__item list__item--unordered">уведомление за всичко, свързано с услугите, които ползвате при нас;</li>
                <li className="list__item list__item--unordered">анализ на клиентската история;</li>
                <li className="list__item list__item--unordered">установим и/или предотвратим незаконосъобразни действия или действия в противоречия с нашите условия за съответните услуги;</li>
            </ul>
            <h3 className="title_3">Данни, които обработваме на това основание:</h3>
            <p className="paragraph">На основание сключения между нас и Вас договор обработваме информация за вида и съдържанието на договорното отношение, както и всяка друга информация, свързана с договорното правоотношение, включително:</p>
            <ul className="list list--unordered">
                <li className="list__item list__item--unordered">лични данни за контакт - адрес за контакт, имейл, телефонен номер;</li>
                <li className="list__item list__item--unordered">данни за идентификация - трите имена, единен граждански номер или личен номер на чужденец, постоянен адрес;</li>
                <li className="list__item list__item--unordered">данни за направените поръчки;</li>
                <li className="list__item list__item--unordered">кореспонденция във връзка с цялостното обслужване - електронна поща, писма, информация за заявките Ви за отстраняване на проблеми, жалби, молби, оплаквания, обратна връзка, която получаваме от Вас;</li>
                <li className="list__item list__item--unordered">информация за кредитна или дебитна карта, номер на банкова сметка или друга банкова и платежна информация във връзка с направените плащания;</li>
            </ul>
            <h3 className="title_3">Друга информация като:</h3>
            <ul className="list list--unordered">
                <li className="list__item list__item--unordered">Клиентски номер, код или друг идентификатор, създаден за идентификация;</li>
                <li className="list__item list__item--unordered">Информация от действията Ви на сайта</li>
            </ul>
            <p className="paragraph">Обработката на посочените лични данни за нас се явява задължителна, за да можем да сключим договора с Вас и да го изпълняваме. Без да ни предоставите горепосочените данни, не бихме могли да изпълняваме задълженията си по договора.</p>
            <h3 className="title_3">Предоставяме лични данни на трети лица</h3>
            <p className="paragraph">Ние предоставяме Ваши лични данни на трети лица, като основната ни цел е да Ви предложим качествено, бързо и комплексно обслужване. Не предоставяме Ваши лични данни на трети лица, преди да се уверим, че са взети всички технически и организационни мерки за защита на тези данни като се стремим да осъществяваме строг контрол за изпълнение на тази цел. В този случай ние оставаме отговорни за конфиденциалността и сигурността на вашите данни.</p>
            <h3 className="title_3">Предоставяме лични данни на следните категории получатели (администратори на лични данни):</h3>
            <ul className="list list--unordered">
                <li className="list__item list__item--unordered">пощенски оператори и куриерски фирми;</li>
                <li className="list__item list__item--unordered">лица, които по възлагане поддържат оборудване, софтуер и хардуер, използвани за обработка на лични данни и необходими за дейността на дружеството</li>
                <li className="list__item list__item--unordered">лица, извършващи консултантски услуги в различни сфери.</li>
            </ul>
            <h3 className="title_3">Кога изтриваме данните, събрани на това основание</h3>
            <p className="paragraph">Данните, събрани на това основание изтриваме 2 години след прекратяване на договорното отношение, независимо дали поради изтичане срока на договора, разваляне или друго основание.</p>
            <h2 className="title_2">ЗА ИЗПЪЛНЕНИЕ НА НОРМАТИВНИ ЗАДЪЛЖЕНИЯ</h2>
            <p className="paragraph">Възможно е в закона да е предвидено задължение за нас да обработваме личните Ви данни. В тези случаи ние сме длъжни да извършим обработката, като например:</p>
            <ul className="list list--unordered">
                <li className="list__item list__item--unordered">Задължения по Закона за мерките срещу изпиране на пари;</li>
                <li className="list__item list__item--unordered">Изпълнение на задължения във връзка с продажбата от разстояние, продажбата извън търговския обект, предвидени в Закона за защита на потребителите;</li>
                <li className="list__item list__item--unordered">Предоставяне на информация на Комисията за защита на потребителите или трети лица, предвидени в Закона за защита на потребителите;</li>
                <li className="list__item list__item--unordered">Предоставяне на информация на Комисията за защита на личните данни във връзка със задължения, предвидени в нормативната уредба за защита на личните данни;</li>
                <li className="list__item list__item--unordered">Задължения, предвидени в Закона за счетоводството и Данъчно- осигурителния процесуален кодекс и други свързани нормативни актове, във връзка с воденето на законосъобразно счетоводство;</li>
                <li className="list__item list__item--unordered">Предоставяне на информация на съда и трети лица, в рамките на производство пред съд, съобразно изискванията на приложимите към производството нормативни актове;</li>
                <li className="list__item list__item--unordered">Удостоверяване на възраст при пазаруване онлайн. Кога изтриваме личните данни, събрани на това основание Данните, събрани съгласно предвидено задължение в закона, изтриваме, след като задължението за събиране и съхранение бъде изпълнено или отпадне. Например:</li>
                <li className="list__item list__item--unordered">по Закона за счетоводството за съхранение и обработка на счетоводни данни (<span className="fancy">11</span> години),</li>
                <li className="list__item list__item--unordered">задължения за предоставяне на информация на съда, компетентни държавни органи и др. основания, предвидени в действащото законодателство (<span className="fancy">5</span> години).</li>
            </ul>
            <h3 className="title_3">Предоставяне на данни на <span className="fancy">3</span>-ти лица</h3>
            <p className="paragraph">Когато по закон за нас е предвидено задължение, е възможно да предоставим Вашите лични данни на компетентния държавен орган, физическо или юридическо лице.</p>
            <h2 className="title_2">СЛЕД ВАШЕ СЪГЛАСИЕ</h2>
            <p className="paragraph">Ние обработваме Вашите лични данни на това основание само след изрично, недвусмислено и доброволно съгласие от Ваша страна. Ние няма да предвиждаме каквито и да е неблагоприятни последици за Вас, ако откажете обработването на личните данни.</p>
            <p className="paragraph">Съгласието е отделно основание за обработване на личните Ви данни и целта на обработката е посочена в него, и не се покрива с целите, изброени в тази политика. Ако ни дадете съответното съгласие и до неговото оттегляне или прекратяване на всякакви договорни отношения с Вас изготвяме подходящи за Вас предложения за продукти/услуги, като извършваме детайлни анализи на Ваши основни лични данни;</p>
            <p className="paragraph">Детайлни анализи е метод за извършване на анализ, който позволява обработване на големи по обем данни посредством статистически модели и алгоритми и други, които включват използване на лични данни, както и процеси на псевдонимизиране и анонимизиране на същите, с цел извличане на информация за тенденции и различни статистически показатели.</p>
            <h3 className="title_3">Данни, които обработваме на това основание:</h3>
            <p className="paragraph">На това основание обработваме само данните, за които сте ни дали изричното си съгласие. Конкретните данни се определят за всеки индивидуален случай. Обикновено тези данни са имена и телефонен номер.</p>
            <h3 className="title_3">Предоставяне на данни на трети лица</h3>
            <p className="paragraph">На това основание можем да предоставим Вашите данни на маркетинг агенции, Фейсбук, Гугъл или други подобни.</p>
            <h3 className="title_3">Оттегляне на съгласие</h3>
            <p className="paragraph">Предоставените съгласия могат да бъдат оттеглени по всяко време. Оттеглянето на съгласието няма отражение върху изпълнението на договорните задължения. Ако оттеглите съгласието си за обработване на лични данни за някой или всички начини, описани по-горе, ние няма да използваме личните Ви данни и информация за определените по-горе цели. Оттеглянето на съгласието не засяга законосъобразността на обработването, основано на дадено съгласие преди неговото оттегляне. За да оттеглите даденото съгласие е необходимо само да използвате сайта ни или
            просто данните ни за контакт.</p>
            <h3 className="title_3">Кога изтриваме данните, събрани на това основание</h3>
            <p className="paragraph">Данните, събрани на това основание, изтриваме при искане от Ваша страна или 12 месеца след първоначалното им събиране.</p>
            <h2 className="title_2">ОБРАБОТКА НА АНОНИМИЗИРАНИ ДАННИ</h2>
            <p className="paragraph">Ние обработваме Вашите данни за статически цели, това означава за анализи, в които резултатите са само обобщаващи и следователно данните са анонимни. Идентифицирането на конкретно лице от тази информация е невъзможно. Вашите данни могат да бъдат и анонимизирани. Анонимизирането представлява алтернатива на изтриването на данните. При анонимизация, всички лични разпознаваеми елементи/ елементи, позволяващи идентифицирането Ви, се заличават необратимо. За анонимизирани данни няма нормативно задължение за изтриване, тъй като не представляват лични данни.</p>
            <h3 className="title_3">Защо и как използваме автоматизирани алгоритми</h3>
            <p className="paragraph">За обработването на Вашите лични данни ние използване частично автоматизирани алгоритми и методи с цел постоянно подобряване на нашите продукти и услуги за адаптиране на продуктите и услугите ни към Вашите нужди по най- добрия възможен начин. Този процес се нарича профилиране.</p>
            <h3 className="title_3">Как защитаваме Вашите лични данни</h3>
            <p className="paragraph">За осигуряване на адекватна защита на данните на компанията и своите клиенти, ние прилагаме всички необходими организационни и технически мерки, предвидени в Закона за защита на личните данни.</p>
            <p className="paragraph">Компанията е установила правила за предотвратяване на злоупотреби и пробиви в сигурността, което подпомага процесите по опазване и обезпечаване сигурността на Вашите данни.</p>
            <p className="paragraph">С цел максимална сигурност при обработка, пренос и съхранение на Вашите данни, може да използваме допълнителни механизми за защита като криптиране, псевдонимизация и др.</p>
            <h3 className="title_3">Лични данни, които сме получили от <span className="fancy">3</span>-ти лица</h3>
            <p className="paragraph">Получаваме лични данни от следните <span className="fancy">3</span>-ти лица: Социални мрежи</p>
            <h3 className="title_3">Права на Потребителите</h3>
            <p className="paragraph">Всеки Потребител на сайта се ползва с всички права за защита на личните данни съгласно българското законодателство и правото на Европейския съюз. Потребителят може да упражни правата си чрез формата за контакт или чрез изпращане на съобщение до имейла ни.</p>
            <p className="paragraph">Всеки Потребител има право на:</p>
            <ul className="list list--unordered">
                <li className="list__item list__item--unordered">Информираност (във връзка с обработването на личните му данни от администратора);</li>
                <li className="list__item list__item--unordered">Достъп до собствените си лични данни;</li>
                <li className="list__item list__item--unordered">Коригиране (ако данните са неточни);</li>
                <li className="list__item list__item--unordered">Изтриване на личните данни (право „да бъдеш забравен“);</li>
                <li className="list__item list__item--unordered">Ограничаване на обработването от страна на администратора или обработващия лични данни;</li>
                <li className="list__item list__item--unordered">Преносимост на личните данни между отделните администратори;</li>
                <li className="list__item list__item--unordered">Възражение спрямо обработването на негови лични данни;</li>
                <li className="list__item list__item--unordered">Субектът на данни има право и да не бъде обект на решение, основаващо се единствено на автоматизирано обработване, включващо профилиране, което поражда правни последствия за субекта на данните или по подобен начин го засяга в значителна степен;</li>
                <li className="list__item list__item--unordered">Право на защита по съдебен или административен ред, в случай, че правата на субекта на данни са били нарушени. Потребителят може да поиска изтриване, ако е налице едно от следните условия:</li>
                <li className="list__item list__item--unordered">Личните данни повече не са необходими за целите, за които са били събрани или обработвани по друг начин;</li>
                <li className="list__item list__item--unordered">Потребителят оттегля своето съгласие, върху което се основава обработването на данните и няма друго правно основание за обработването;</li>
                <li className="list__item list__item--unordered">Потребителят данните възразява срещу обработването и няма законни основания за обработването, които да имат преимущество;</li>
                <li className="list__item list__item--unordered">Личните данни са били обработвани незаконосъобразно;</li>
                <li className="list__item list__item--unordered">Личните данни трябва да бъдат изтрити с цел спазването на правно задължение по правото на Съюза или правото на държава членка, което се прилага спрямо администратора;</li>
                <li className="list__item list__item--unordered">Личните данни са били събрани във връзка с предлагането на услуги на информационното общество на деца и съгласието е дадено от носещия родителска отговорност за детето. Потребителят има право да ограничи обработването на своите лични данни от страна на администратора, когато:</li>
                <li className="list__item list__item--unordered">Оспори точността на личните данни. В този случай ограничаването на обработването е за срок, който позволява на администратора да провери точността на личните данни;</li>
                <li className="list__item list__item--unordered">Обработването е неправомерно, но Потребителят не желае личните данни да бъдат изтрити, а изисква вместо това ограничаване на използването им;</li>
                <li className="list__item list__item--unordered">Администраторът не се нуждае повече от личните данни за целите наобработването, но Потребителят ги изисква за установяването, упражняването или защитата на правни претенции;</li>
                <li className="list__item list__item--unordered">Възразява срещу обработването в очакване на проверка дали законните основания на администратора имат преимущество пред интересите на Потребителя.</li>
            </ul>
            <h3 className="title_3">Право на преносимост.</h3>
            <p className="paragraph">Субектът на данните има право да получи личните данни, които го засягат и които той е предоставил на администратор, в структуриран, широко използван и пригоден за машинно четене формат и има правото да прехвърли тези данни на друг администратор без възпрепятстване от администратора, на когото личните данни са предоставени, когато обработването е основано на съгласие или на договорно задължение и обработването се извършва по автоматизиран начин. Когато упражнява правото си на преносимост на данните, субектът на данните има право да получи и пряко прехвърляне на личните данни от един администратор към друг, когато това е технически осъществимо.</p>
            <h3 className="title_3">Право на възражение.</h3>
            <p className="paragraph">Потребителите имат право да възразят пред администратора срещу обработването на личните им данни. Администраторът на лични данни е длъжен да прекрати обработването, освен ако не докаже, че съществуват убедителни законови основания за обработването, които имат предимство пред интересите, правата и свободите на субекта на данни, или за установяването, упражняването или защитата на правни претенции. При възразяване срещу обработването на лични данни за целите на директния маркетинг обработването следва да се прекрати незабавно.</p>
            <h3 className="title_3">Жалба до надзорния орган</h3>
            <p className="paragraph">Всеки Потребител има право да подаде жалба срещу незаконосъобразно обработване на личните му данни до Комисия за защита на личните данни или до компетентния съд.</p>
            <h3 className="title_3">Поддържане на регистър</h3>
            <p className="paragraph">Ние поддържаме регистър на дейностите по обработване, за които отговоряме. Този регистър съдържа цялата по- долу посочена информация:</p>
            <ul className="list list--unordered">
                <li className="list__item list__item--unordered">Името и координатите за връзка на администратора</li>
                <li className="list__item list__item--unordered">Целите на обработването;</li>
                <li className="list__item list__item--unordered">Описание на категориите субекти на данни и на категориите лични данни;</li>
                <li className="list__item list__item--unordered">Категориите получатели, пред които са или ще бъдат разкрити личните данни,</li>
                <li className="list__item list__item--unordered">Включително получателите в трети държави или международни организации;</li>
                <li className="list__item list__item--unordered">Когато е възможно, предвидените срокове за изтриване на различните категории данни;</li>
                <li className="list__item list__item--unordered">Когато е възможно, общо описание на техническите и организационни</li>
            </ul>
        </section>
    </>
}
