export default function PageAboutBG() {
    return <>
        <header className="header">
            <h1 className="title">Кои сме ние?</h1>
            <p className="subtitle">„Всичко е до доверие в практиките, които доказано работят в комбинация с разработването на по-добри и по-ефективни решения за нашите клиенти“</p>
        </header>
        <section className="section">
            <p className="paragraph">Ние от Лимпио твърдо вярваме в прецизността и качеството на изпълнение на всеки проект.</p>
            <p className="paragraph">Нашата мисия е да въведем нови стандарти в сферата на почистването. Ето защо избрахме да се доверим на напълно иновативна концепция, която гарантира не просто чистота, но и една по-безопасна среда.</p>
            <p className="paragraph">Избрахме селекция от почистващи средства, които не само ще почистят, но ще дезинфекцират и премахнат неприятната миризма от всички повърхности. Приоритизирайки опазването на околната среда, подбрахме биоразградими ЕКО препарати в опаковки от рециклирана пластмаса, които сами по себе си са веган и никога не са тествани върху животни.</p>
            <p className="paragraph">Наша цел е да допринесем за нивото на комфорт и безопасност на хората, когато става въпрос за дезинфекциращи процеси. Това е много важна тема в наши дни и нашите клиенти заслужават прозрачност и сигурност в начините, по които избираме да вършим работата си. Ние твърдим, че го правим и го правим добре.</p>
            <p className="paragraph">Едно от най-важните неща за нашия бранд е последователността, така че можете да сте сигурни, че няма да има изненади следващия път, когато ни се обадите. Ние ще изпълняваме нашата част по абсолютно същия начин всеки път и познайте какво - ще бъде добре, вероятно дори много добре...и може би дори твърде добре за парите, които сте похарчили.</p>
        </section>
        <section className="section">
            <h1 className="title_1">Нашите клиенти</h1>
        </section>
    </>
}
