import { Routes, Route } from "react-router-dom"

import Home from "./home"
import About from "./about"
import Boutique from "./boutique"
import Service from "./service"
import Contact from "./contact"
import Data from "./data"
import Cookie from "./cookie"
import Site from "./site"

export default function Main() {
    return <>
        <Routes>
            <Route path="home" element={ <Home/> } exact />
            <Route path="about" element={ <About/> } exact />
            <Route path="boutique" element={ <Boutique/> } exact />
            <Route path="service" element={ <Service/> } exact />
            <Route path="contact" element={ <Contact/> } exact />
            <Route path="data" element={ <Data/> } exact />
            <Route path="cookie" element={ <Cookie/> } exact />
            <Route path="site" element={ <Site/> } exact />
            <Route path="*" element={ <p>YOLO</p> }/>
        </Routes>
    </>
}
