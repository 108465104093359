import { useParams } from "react-router-dom"
import { HashLink } from "react-router-hash-link"

// const translate = {
//     en: {
//         data: `Personal data protection policy`,
//         cookie: `Cookie policy`,
//         site: `General conditions of the terms of use`,
//     },
//     bg: {
//         data: `Политика за защита на личните данни`,
//         cookie: `Политика за бисквитки`,
//         site: `Общи условия на сайта`,
//     },
// }

const translate = {
    en: {
        data: `Data policy`,
        cookie: `Cookie policy`,
        site: `Terms of use`,
    },
    bg: {
        data: `Политика за личните данни`,
        cookie: `Политика за бисквитки`,
        site: `Общи условия`,
    },
}

export default function Footer() {
    const { language } = useParams()
    const translation = translate[language]
    return <>
        <footer id="footer">
            <nav className="menu_terms">
                <HashLink className="menu_terms__item" to={ `/${ language }/data` } smooth>{ translation.data }</HashLink>
                <HashLink className="menu_terms__item" to={ `/${ language }/cookie` } smooth>{ translation.cookie }</HashLink>
                <HashLink className="menu_terms__item" to={ `/${ language }/site` } smooth>{ translation.site }</HashLink>
            </nav>
            <h1 className="copyright">© 2021 Limpio</h1>
        </footer>
    </>
}
