import { HashLink } from "react-router-hash-link"

export default function PageSiteBG() {
    return <>
        <section className="section">
            <h1 className="title_1">Общи условия и легална информация</h1>
            <p className="paragraph">Условия за използване на уебсайт <HashLink to="http://www.limpiointernational.com" smooth>http://www.limpiointernational.com</HashLink></p>
            <p className="paragraph">Настоящите общи условия уреждат взаимоотношенията между Лимпио Интернешънъл ЕООД, по-долу за краткост "Търговец", от една страна, и Потребителите на интернет страници и услуги, намиращи се на уебсайт <HashLink to="http://www.limpiointernational.com">http://www.limpiointernational.com</HashLink>, наричани по-долу за краткост потребители, от друга.</p>
            <p className="paragraph">Лимпио Интернешънъл ЕООД е дружество, регистрирано по Търговския закон на Република България с ЕИК 202898179 адрес , имейл адрес info@limpiointernational.com, телефон 0895738848</p>
            <p className="paragraph">Моля, прочетете изцяло публикуваните Общи условия преди да използвате информационните и търговски услуги, предлагани на Сайта (наричани за краткост Услуги).</p>
            <p className="paragraph">Настоящият документ съдържа информация за дейността на Лимпио Интернешънъл ЕООД и общите условия за ползване на услугите, предоставяни от Лимпио Интернешънъл ЕООД, като уреждат отношенията между нас и всеки един от потребителите ни.</p>
            <p className="paragraph">Потвърждаването на Общите условия е необходимо и задължително условие за сключването на договора между Потребителя и Търговеца.</p>
            <p className="paragraph">С приемането на Общите условия Потребителят се съгласява да да бъдат обработвани неговите лични данни на основание сключения между него и Търговеца договор.</p>
            <h3 className="title_3">Дефиниции</h3>
            <p className="paragraph">За целите на настоящите общи условия долупосочените понятия следва да се разбират в следния смисъл:</p>
            <ul className="list list--unordered">
                <li className="list__item list__item--unordered">Сайт - <HashLink to="http://www.limpiointernational.com" smooth>http://www.limpiointernational.com</HashLink> и всички негови подстраници.</li>
                <li className="list__item list__item--unordered">Потребител - всяко физическо лице, което придобива стоки или ползва услуги, които не са предназначени за извършване на търговска или професионална дейност, и всяко физическо лице, което като страна по договор по този закон действа извън рамките на своята търговска или професионална дейност.</li>
                <li className="list__item list__item--unordered">Общи условия - настоящите Общи условия, които включват условия за ползване, бисквитки, правила за регистрация и доставка, доброволно решаване на спорове, формуляри за отказ от договор и замяна и всяка друга правнозначима информация, която се намира на Сайта.</li>
                <li className="list__item list__item--unordered">Лични данни - информация за физическо лице, която разкрива неговата физическа, психологическа, умствена, семейна, икономическа, културна или обществена идентичност.</li>
                <li className="list__item list__item--unordered">Стока - движима материална вещ, с изключение на вещите, продавани при принудително изпълнение или чрез други мерки от оправомощени от закона органи, както и вещи, изоставени или отнети в полза на държавата, обявени за продажба от държавни органи. Стоки са и вода, газ и електрическа енергия, когато се предлагат за продажба, опаковани в ограничен обем или в определено количество.</li>
                <li className="list__item list__item--unordered">Договор за продажба - договор, по силата на който търговецът прехвърля или се задължава да прехвърли собствеността на стоки на потребителя, а потребителят заплаща или се задължава да заплати цената за тях, включително договорите, имащи за предмет едновременно стоки и услуги.</li>
                <li className="list__item list__item--unordered">Услуга - всяка материална или интелектуална дейност, която се извършва по независим начин, предназначена е за друго лице и не е с основен предмет прехвърляне владение на вещ.</li>
                <li className="list__item list__item--unordered">Договор за услуга - договор, различен от договор за продажба, по силата на който търговецът предоставя или се задължава да предостави услуга на потребителя, а потребителят заплаща или се задължава да заплати цената за нея.</li>
                <li className="list__item list__item--unordered">Процедура за алтернативно решаване на потребителски спорове - процедура за извънсъдебно решаване на потребителски спорове, отговаряща на изискванията на този закон и осъществявана от орган за алтернативно решаване на потребителски спорове.</li>
            </ul>
            <h3 className="title_3">Предоставяни услуги</h3>
            <p className="paragraph">1. На Сайта Потребителите имат възможност да сключват договори за услуга, покупко-продажба и доставка на предлаганите от Търговеца стоки и услуги.</p>
            <h3 className="title_3">Поръчка</h3>
            <p className="paragraph">2. Потребителите използват интерфейса на уебсайта, за да сключват договори с Търговеца за предлаганите стоки и услуги.</p>
            <p className="paragraph">2.1. Договорът за покупко-продажба на стока или за услуга се счита за сключен от момента на потвърждаването на поръчката от Търговеца</p>
            <p className="paragraph">2.2. При липса на наличност от дадена стока или невъзможност за извършване на дадена услуга Търговецът си запазва правото да откаже поръчката.</p>
            <p className="paragraph">2.3. След избиране на една или повече стоки или услуги, предлагани на уебсайта на Търговеца, Потребителят трябва да добави същите в списъка си със стоки или услуги за покупка.</p>
            <p className="paragraph">2.4. Необходимо е Потребителят да предостави данни за извършване на доставката и да избере способ и момент на плащане на цената, след което да потвърди поръчката чрез интерфейса на сайта.</p>
            <p className="paragraph">2.5. При извършване на поръчка, Потребителят получава потвърждение по имейл, че поръчката му е приета.</p>
            <p className="paragraph">3. Търговецът има право да откаже да сключи договор с некоректен Потребител.</p>
            <p className="paragraph">3.1. Търговецът има право да третира Потребител като некоректен в случаите, когато:</p>
            <ol className="list list--ordered">
                <li className="list__item list__item--ordered">е налице неспазване от страна на Потребителя на Общите условия;</li>
                <li className="list__item list__item--ordered">е установено некоректно, арогантно или грубо отношение към представителите на Търговеца;</li>
                <li className="list__item list__item--ordered">са установени системни злоупотреби от Потребителя спрямо Търговеца.</li>
            </ol>
            <h3 className="title_3">Цени</h3>
            <p className="paragraph">4. Цените на предлаганите стоки или услуги са тези, посочени на уебсайта на Търговеца към момента на извършване на поръчка, освен в случаи на очевидна грешка.</p>
            <p className="paragraph">4.1. Цените на стоките и услугите включват ДДС, в случаите, в които е предвидено начисляването му.</p>
            <p className="paragraph">5. Търговецът си запазва правото да променя по всяко време и без предизвестие цените на предлаганите на сайта стоки и услуги, като такива промени няма да засягат вече извършени поръчки.</p>
            <p className="paragraph">6. Търговецът може да предоставя отстъпки за стоките и услугите, предлагани на сайта, съгласно българското законодателство и правила, определени от Търговеца. Правилата, приложими за такива отстъпки, са налични на мястото, където е показана отстъпката. Отстъпки могат да се предоставят под различни форми (напр. промоции, отстъпки за лоялност, предоставяни индивидуално, на случаен принцип или в резултат на участие в състезание или клиентско проучване).</p>
            <p className="paragraph">6.1. Различните видове отстъпки не могат да се комбинират при поръчка и закупуване на една и съща стока/ услуга.</p>
            <h3 className="title_3">Плащане</h3>
            <p className="paragraph">7. Когато Потребителят връща стока или услуга с правото на възстановяване на заплатената сума поради каквато и да било причина, цената, обект на възстановяване, се намалява със стойността на получената отстъпка, приложена върху стоката или услугата, и само действително платената сума подлежи на възстановяване.</p>
            <p className="paragraph">8. Потребителят може да заплати цената на поръчаните стоки/услуги като използва по свой избор една от изброените на уебсайта възможности. На Сайта е възможно плащане чрез следните методи:</p>
            <ul className="list list--unordered">
                <li className="list__item list__item--unordered">в брой</li>
                <li className="list__item list__item--unordered">наложен платеж</li>
                <li className="list__item list__item--unordered">банков превод</li>
                <li className="list__item list__item--unordered">с кредитна или дебитна карта</li>
            </ul>
            <p className="paragraph">9. Ако Потребителят избере опцията за доставка с куриер и плащане с наложен платеж, той трябва да заплати цената на поръчаните артикули заедно с цената за доставка на куриера при получаването на стоката.</p>
            <p className="paragraph">10. Ако Потребителят избере метод на плащане, включващ трета страна - доставчик на платежни услуги, Потребителят може да бъдете обвързан от разпоредбите и условията и/или таксите на такава трета страна.</p>
            <p className="paragraph">11. Търговецът не носи отговорност, ако даден метод плащане, включващ трета страна - доставчик на платежни услуги, не е наличен или по друг начин не функционира поради причини, които не могат да бъдат вменени във вина на Търговеца.</p>
            <h3 className="title_3">Отказ от договора и замяна</h3>
            <p className="paragraph">12. Потребителят има право да се откаже от договора без да посочва причина, без да дължи обезщетение или неустойка в 14-дневен срок, считано от датата на приемане на стоките от Потребителя или от трето лице, а при договор за услуга – от сключване на договора за услуга.</p>
            <p className="paragraph">13. За да упражни правото си по настоящата клауза, Потребителят трябва да уведоми недвусмислено Търговеца за решението си да се откаже от договора, като индивидуализира стоките/ услугите, които желае да върне, чрез предоставяне на всички данни за осъществената поръчка и доставка, включително, но не само: съдържание и стойност на поръчката, данни на лицето, направило поръчката, данни на лицето, приело доставката, и дата на доставката.</p>
            <p className="paragraph">14. Търговецът публикува на сайта си формуляр за упражняване правото на отказ от договора.</p>
            <p className="paragraph">15. За упражняване правото на отказ Търговецът предоставя на потребителя възможност за избор да попълни и да изпрати по електронен път чрез интернет страницата стандартния формуляр за отказ или друго недвусмислено заявление. В тези случаи Търговецът незабавно изпраща на потребителя потвърждение за получаване на отказа му на траен носител.</p>
            <p className="paragraph">16. Потребителят е длъжен да върне стоките за своя сметка задължително заедно с касовата бележка и фактурата, ако има такава, като ги предаде на Търговеца или на упълномощено от последното лице, в срок от 14 дни от датата, на която Потребителят е упражнил правото си на отказ от договора.</p>
            <p className="paragraph">17. При връщане стоката трябва да е в оригиналната си опаковка, без следи от употреба или нарушаване на търговския вид.</p>
            <p className="paragraph">18. Търговецът има право да отложи възстановяването на плащанията до получаване на стоките обратно или докато не бъде представено доказателство, че е стоките са изпратени обратно, в зависимост от това, кое от двете събития е настъпило по-рано.</p>
            <p className="paragraph">19. В случай че Потребителят не изпълни задължението си за връщане на стоакта, без да уведоми Търговеца за забавянето и без да предостави уважителна причина за същото, се счита, че той е оттеглил изявлението си за упражняване на отказ от договора.</p>
            <p className="paragraph">20. Когато във връзка с изпълнението по договора Търговецът е направил разноски и Потребителят се откаже от договора, Търговецът има право да задържи съответната сума за направените разноски или да изисква тяхното плащане.</p>
            <p className="paragraph">21. Потребителят няма право да се откаже от договора в случай че предмет на същия са:</p>
            <ol className="list list--ordered">
                <li className="list__item list__item--ordered">за предоставяне на услуги, при които услугата е предоставена напълно и изпълнението й е започнало с изричното предварително съгласие на потребителя и потвърждение от негова страна, че знае, че ще загуби правото си на отказ, след като договорът бъде изпълнен изцяло от търговеца.</li>
                <li className="list__item list__item--ordered">за доставка на стоки или услуги, чиято цена зависи от колебанията на финансовия пазар, които не могат да бъдат контролирани от търговеца и които могат да настъпят по време на срока за упражняване правото на отказ, включително за доставка на алкохолни напитки, чиято цена е договорена при сключването на договора за продажба, при които доставката може да бъде извършена в срок не по-рано от 30 дни</li>
                <li className="list__item list__item--ordered">при които потребителят изрично е поискал от търговеца да го посети в дома му с цел извършване на неотложни дейности за ремонт или поддръжка; когато при такова посещение търговецът предоставя и други услуги в допълнение към тези, поискани от потребителя, или достави стоки, различни от резервните части, необходими за извършване на ремонта или на поддръжката, правото на отказ се прилага за тези допълнителни услуги или стоки</li>
            </ol>
            <p className="paragraph">22. Търговецът възстановява на Потребителя заплатената от него цена за върнатите стоки.</p>
            <p className="paragraph">22.1. В случай че потребителят е направи плащане по договора с банкова карта и е упражнил правото си на отказ от договора, възстановяването на суми се извършва чрез нареждане на обратна операция по картата с която е направено плащането в срок от 7 работни дни.</p>
            <h3 className="title_3">Гаранции и рекламации</h3>
            <p className="paragraph">23. Потребителят има право на рекламация за всяко несъответствие на стоката или услугата с договореното/поръчаното, когато след доставката, са открити несъответствия с договора за продажба.</p>
            <p className="paragraph">24. Търговецът не носи отговорност за естественото износване на стоките.</p>
            <p className="paragraph">25. Всяко несъответствие на потребителската стока с договора за продажба, което се прояви до 6 месеца след доставката на стоката, се смята, че е съществувало при доставянето ѝ, освен ако се докаже, че липсата на съответствие се дължи на естеството на стоката или на характера на несъответствието.</p>
            <p className="paragraph">26. Потребителят не може да оспори съответствието на потребителската стока с договора за продажбата ѝ, когато:</p>
            <ol className="list list--ordered">
                <li className="list__item list__item--ordered">при сключването на договора е знаел или не е могъл да не знае за несъответствието;</li>
                <li className="list__item list__item--ordered">несъответствието се дължи на материали, предоставени от потребителя.</li>
            </ol>
            <p className="paragraph">27. Потребителят има право да предяви рекламация на стоката или услугата, независимо от това дали производителят или търговецът е предоставил търговска гаранция на стоката или услугата.</p>
            <p className="paragraph">28. Когато удовлетворяването на рекламацията се извършва чрез замяна на стоката с друга, съответстваща на договореното, Търговецът ще запази на потребителя първоначалните гаранционни условия.</p>
            <p className="paragraph">29. При предявяване на рекламацията потребителят може да претендира за възстановяване на заплатената сума, за заменяне на стоката с друга, съответстваща на договореното или за отбив от цената.</p>
            <p className="paragraph">30. Рекламацията се подава устно на посочения от Търговеца телефон или писмено чрез, посочения имейл, по поща или подадена до адреса на дружеството. Търговецът представя на сайта си достъп до формуляр за рекламация.</p>
            <p className="paragraph">31. При предявяване на рекламация потребителят посочва предмета на рекламацията, предпочитания от него начин за удовлетворяване на рекламацията, съответно размера на претендираната сума, и адрес, телефон и email за контакт.</p>
            <p className="paragraph">32. При подаване на рекламация потребителят задължително следва да приложи и документите, на които се основава претенцията, а именно:</p>
            <ol className="list list--ordered">
                <li className="list__item list__item--ordered">касова бележка или фактура;</li>
                <li className="list__item list__item--ordered">протоколи, актове или други документи, установяващи несъответствието на стоката или услугата с договореното;</li>
                <li className="list__item list__item--ordered">други документи, установяващи претенцията по основание и размер.</li>
            </ol>
            <p className="paragraph">33. Рекламацията на потребителска стока може да се предяви до две години от доставката на стоката, но не по-късно от два месеца от установяване на несъответствието с договореното. Рекламацията на услуги може да се предяви до 14 дни от откриване на несъответствието на услугата с договореното.</p>
            <p className="paragraph">34. Срокът спира да тече през времето, необходимо за постигане на споразумение между продавача и потребителя за решаване на спора.</p>
            <p className="paragraph">35. Ако Търговецът е предоставил търговска гаранция на стоката и срокът на гаранцията е по-дълъг от сроковете за предявяване на рекламацията по ал. 1, рекламацията може да се предяви до изтичането на срока на търговската гаранция.</p>
            <p className="paragraph">36. Предявяването на рекламация не е пречка за предявяване на иск.</p>
            <p className="paragraph">37. Търговецът поддържа регистър на предявените рекламации. На Потребителя се изпраща документ на посочения от него имейл, в който е посочен номера на рекламацията от регистъра и вида на стоката.</p>
            <p className="paragraph">38. Когато Търговецът удовлетвори рекламацията, издава акт за това, който се съставя в два екземпляра, и предоставя задължително един екземпляр на Потребителя</p>
            <p className="paragraph">39. Търговецът при основателна рекламация привежда стоката в съответствие с договора за продажба в рамките на един месец, считано от предявяването на рекламацията от Потребителя.</p>
            <p className="paragraph">39.1. Ако стоката не е поправена и след изтичането на срока по предходната алинея, Потребителят има право да развали договора и да му бъде възстановена заплатената сума или да иска намаляване на цената на потребителската стока съгласно чл. 114 от ЗЗП.</p>
            <p className="paragraph">39.2. Привеждането на потребителската стока в съответствие с договора за продажба е безплатно за Потребителя. Той не дължи разходи за експедиране на потребителската стока или за материали и труд, свързани с ремонта й, и не понася значителни неудобства.</p>
            <p className="paragraph">40. При несъответствие на потребителската стока с договора за продажба и когато Потребителят не е удовлетворен от решаването на рекламацията, той има право на избор между една от следните възможности:</p>
            <ol className="list list--ordered">
                <li className="list__item list__item--ordered">разваляне на договора и възстановяване на заплатената от него сума</li>
                <li className="list__item list__item--ordered">намаляване на цената.</li>
            </ol>
            <p className="paragraph">41. Потребителят не може да претендира за възстановяване на заплатената сума или за намаляване цената на стоката, когато търговецът се съгласи да бъде извършена замяна на потребителската стока с нова или да се поправи стоката в рамките на един месец от предявяване на рекламацията от потребителя.</p>
            <p className="paragraph">42. Търговецът е длъжен да удовлетвори искане за разваляне на договора и да възстанови заплатената от потребителя сума, когато след като е удовлетворил три рекламации на потребителя чрез извършване на ремонт на една и съща стока, в рамките на срока на гаранцията, е налице следваща поява на несъответствие на стоката с договора за продажба.</p>
            <p className="paragraph">43. Потребителят не може да претендира за разваляне на договора, ако несъответствието на потребителската стока с договора е незначително.</p>
            <h3 className="title_3">Интелектуална собственост</h3>
            <p className="paragraph">44. Правата на интелектуална собственост върху всички материали и ресурси, разположени на уебсайта на Търговеца (включително наличните бази данни), са обект на закрила по Закона за авторското право и сродните права, принадлежат на Търговеца или на съответно указаното лице, преотстъпило правото на ползване на Търговеца, и не могат да бъдат използвани в нарушение на действащото законодателство.</p>
            <p className="paragraph">45. При копиране или възпроизвеждане на информация вън от допустимото, както и при всяко друго нарушение на правата на интелектуална собственост върху ресурсите на Търговеца, Търговецът има правото да претендира обезщетение и за претърпените преки и косвени вреди в пълен размер.</p>
            <p className="paragraph">46. Освен в случаите, когато е изрично уговорено, Потребителят не може да възпроизвежда, променя, заличава, публикува, разпространява и разгласява по друг начин информационните ресурси, публикувани на уебсайта на Търговеца.</p>
            <p className="paragraph">47. Търговецът се задължава да полага дължимата грижа да осигури възможност на Потребителя за нормален достъп до предоставяните услуги.</p>
            <p className="paragraph">48. Търговецът си запазва правото да преустанови достъпа до предоставените услуги. Търговецът има право, но не и задължение, по своя преценка да изтрива информационни ресурси и материали, публикувани в сайта си.</p>
            <h3 className="title_3">Прекратяване и разваляне на договора</h3>
            <p className="paragraph">49. Търговецът има право по свое усмотрение, без да отправя предизвестие да прекрати едностранно договора, в случай че установи, че предоставяните услуги се използват в нарушение на настоящите общи условия, законодателството в Република България и общоприетите нравствени норми.</p>
            <p className="paragraph">50. Освен в случаите, предвидени в настоящите Общи условия, договорът между страните се прекратява и при преустановяване на дейността на Търговеца или прекратяване поддържането на уебсайта му.</p>
            <p className="paragraph">51. Извън посочените по-горе случаи всяка от страните може да развали настоящия договор с отправяне на едноседмично предизвестие до другата страна в случай на неизпълнение на задълженията по договора.</p>
            <p className="paragraph">52. Писмената форма на договора се счита спазена с изпращане на съобщение на електронна поща (e-mail), натискане на електронен бутон на страница със съдържание, което се попълва или избира от Потребителя или отбелязване в поле (чек бокс) в уебсайта и др. подобни, доколкото изявлението е записано технически по начин, който дава възможност да бъде възпроизведено.</p>
            <h3 className="title_3">Спасителна клауза</h3>
            <p className="paragraph">53. Страните декларират, че, в случай че някоя/и от клаузите по настоящите Общи условия се окаже/окажат недействителна/и, това няма да влече недействителност на целия договор или на други негови части. Недействителната клауза ще бъде заместена от повелителните норми на закона или установената практика.</p>
            <h3 className="title_3">Изменение на общите условия</h3>
            <p className="paragraph">54. Търговецът се задължава да уведоми Потребителите за всяко изменение в настоящите общите условия в 7-дневен срок от настъпването на това обстоятелство на посочения от Потребителя имейл адрес.</p>
            <p className="paragraph">55. Когато не е съгласен с измененията в общите условия, Потребителят има право да се откаже от договора, без да посочва причина и без да дължи обезщетение или неустойка. За да упражни това си право, Потребителят следва да уведоми Търговеца в срок от един месец от получаването на съобщението по предходния член.</p>
            <p className="paragraph">56. В случай че Потребителят не упражни правото си да се откаже от договора по реда, уреден в настоящите общи условия, се счита, че изменението е прието от Потребителя без възражения</p>
            <h3 className="title_3">Приложимо право</h3>
            <p className="paragraph">57. По всички неуредени от настоящите Общи условия въпроси се прилагат разпоредбите на действащото законодателство на Република България.</p>
        </section>
    </>
}
