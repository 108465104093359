import { useLocation, useNavigate, useParams } from "react-router-dom"

import languages from 'utility/language'

export default function Language({ className }) {
    const location = useLocation()
    const navigate = useNavigate()
    const { language } = useParams()

    const click = language => {
        if (languages.includes(language)) {
            const parts = location.pathname.split(`/`)
            parts[1] = language
            navigate(parts.join(`/`))
        }
    }

    const make_button = ({ select, text }) => <button className={ `menu__link ${ select === language ? `menu__link--selected` : `` }` } onClick={ _ => click(select) }>{ text.toUpperCase() }</button>
    
    return <>
        <nav className={ className }>
            { make_button({ select: `en`, text: `EN` }) }
            { make_button({ select: `bg`, text: `БГ` }) }
        </nav>
    </>
}
