import Page from 'component/page'

import EN from './en'
import BG from './bg'

const translation = {
    en: <EN/>,
    bg: <BG/>,
}

export default function PageService() {
    return <Page translation={ translation }/>
}
