export default function PageAboutEN() {
    return <>
        <header className="header">
            <h1 className="title">Who are we?</h1>
            <p className="subtitle">"It's all about trusting practices that are proven to work in combination with developing better and more efficient solutions for our clients"</p>
        </header>
        <section className="section">
            <p className="paragraph">At Limpio, we firmly believe in the precision and quality of execution of each project.</p>
            <p className="paragraph">Our mission is to introduce new standards in the field of cleaning. That's why we chose to trust to completely innovative concept that guarantees not just cleanliness, but also a safer environment.</p>
            <p className="paragraph">We have chosen a selection of cleaning products that will not jus clean, but also disinfect and deodorize all surfaces. Prioritizing environmental protection, we have selected biodegradable ECO cleaning liquids in recycled plastic packaging, which are themselves vegan and never tested on animals.</p>
            <p className="paragraph">Our goal is to contribute to people's level of comfort and safety when it comes to disinfection processes. This is a very important topic these days and our clients deserve transparency and certainty in the ways we choose to do our work. We claim to do it and we do it well.</p>
            <p className="paragraph">One of the most important things about our brand is consistency, so you can be sure there will be no surprises the next time you call us. We'll do our job the exact same way every time, and guess what - it'll be good, probably even very good...and maybe even too good for the money you spent.</p>
        </section>
        <section className="section">
            <h1 className="title_1">Our customers</h1>
        </section>
    </>
}
