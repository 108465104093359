export default function PageCookieBG() {
    return <>
        <section className="section">
            <h1 className="title_1">Политика за „бисквитки“</h1>
            <h2 className="title_2">Политика за „бисквитки“</h2>
            <h3 className="title_3">Използване на „бисквитки“</h3>
            <p className="paragraph">„Бисквитките“ са кратки текстови файлове или малки пакети от информация, които се съхраняват чрез Интернет браузера на Вашето крайно устройство (компютър, таблет, лаптоп или мобилен телефон), когато посещавате различни сайтове и страници в Интернет. Основната цел на „бисквитките“ е да правят потребителя разпознаваем, когато той се завръща отново на Уебсайта. Някои „бисквитки“ имат и по-специфично приложение, като например да запаметяват потребителското поведение на сайта и да улесняват потребителя при ползването на Уебсайта. Повече информация за това как функционират „бисквитките“ можете да намерите в Интернет.</p>
            <h2 className="title_2">Как се използват „бисквитки“ на този Уебсайт?</h2>
            <p className="paragraph">Ние използваме „бисквитки“ на този Уебсайт предимно с цел улесняване на ползваемостта на сайта, подобряване на неговата работа и съхраняване на информация за потребителското поведение. При този процес не се съхраняват никакви лични данни, т.е. чрез “бисквитките“ на сайта не може да Ви идентифицираме като личност, поради което спрямо събирането на тази информация не се прилага Закона за защита на личните данни. Събраната информация от „бисквитки“ обикновено се използва в обобщен вид с цел анализ на потребителското поведение на Уебсайта, което ни позволява да подобряваме функционалността на сайта, потребителските пътеки и ползваното съдържание.</p>
            <h2 className="title_2">Какви „бисквитки“ се използват на този Уебсайт?</h2>
            <h3 className="title_3">Сесийни „бисквитки“</h3>
            <p className="paragraph">Този тип „бисквитки“ Ви улеснява в ползването на сайта, като те съхраняват информация временно, само в рамките на сесията на използвания браузер. Обикновено информацията, която се съхранява чрез тях, е какви стоки или услуги сте добавили в количката, кои страници на сайта сте посетили и как сте стигнали до дадена информация. Тези „бисквитки“ не събират информация от Вашето крайно устройство и се изтриват автоматично, когато напуснете Уебсайта или прекратите сесията на Вашия браузър.</p>
            <h3 className="title_3">Постоянни "бисквитки"</h3>
            <p className="paragraph">Те ни дават възможност да съхраняваме конкретна информация за сърфирането, като например анализиране посещенията на сайта, как вие сте достигнали до Уебсайта, какви страници сте прегледали, какви опции сте избрали, както и накъде сте се насочили през този Уебсайт. Проследяването на тази информация, ни дава възможност да правим подобрения на Уебсайта, включително да коригираме грешки и да разширяваме съдържанието. Срокът на съхранение на този вид “бисквитки” варира според конкретното им предназначение.</p>
            <h3 className="title_3">"Бисквитки" на трета страна</h3>
            <p className="paragraph">На нашия Уебсайт има препратки към други сайтове или вградено съдържание от други сайтове, например от Facebook, YouTube, Twitter, Google+, LinkedIn, уебсайтове на партньори. Възможно е при посещаването на тези сайтове или отварянето на съдържанието от тях, да се складират на Вашето крайно устройство „бисквитки“ от тези уебсайтове. Именно тези „бисквитки“ се дефинират като „бисквитки на трети страни“, като нямаме контрол върху генерирането и управлението на тези „бисквитки“. За това Ви съветваме да потърсите информация за тях и за начина им на управление на уебсайтовете на съответните трети страни.</p>
            <h2 className="title_2">Как мога да управлявам използването на „бисквитки“ от този Уебсайт?</h2>
            <p className="paragraph">Всички браузъри позволяват управлението на „бисквитки“ от специално създадена за целта папка на Вашия браузър. Можете да блокирате получаването на „бисквитки“, да изтриете всички или част от тях или да зададете Вашите настройки за предпочитания по отношение на използването на „бисквитки“ преди да инициирате посещение на сайта ни. Имайте предвид, че изтриването или блокирането на „бисквитки“ може да повлияе неблагоприятно върху функциите на нашия Уебсайт, а оттам и върху Вашето потребителско преживяване на него.</p>
            <h2 className="title_2">Изключване или блокиране на „бисквитки“</h2>
            <p className="paragraph">Контролирането, изключването или блокирането на „бисквитките“ се управлява от настройките на Вашия браузер. Имайте предвид, че пълната забрана на използването на всички „бисквитки“, може да рефлектира върху функционалното представяне на сайта, неговата ефективност и достъпването на определена информация.</p>
        </section>
    </>
}
