import Image from 'component/image'

export default function PageBoutiqueBG() {
    return <>
        <header className="header">
            <h1 className="title">Бутиково почистване</h1>
            <p className="subtitle">Чистя, следователно съществувам…</p>
        </header>
        <section className="section">
            <h1 className="title_1">Какво е бутиково почистване?</h1>
            <p className="paragraph">Това е една изцяло нова концепция, която  предлага холистичен подход в почистването. Методът е изграден, съобразявайки се с реалните нужди на съвременния човек и цели да оптимизира  качеството му  на живот. Тази услуга ще елиминира много от източниците на стрес, свързани с трудоемката поддръжка на дома и ще преобрази представата Ви за здравословна среда, уют и сигурност.</p>
        </section>
        <section className="section">
            <h1 className="title_1">С какво се отличаваме?</h1>
            <ul className="list list--unordered">
                <li className="list__item list__item--unordered">Еко препарати, щадящи Вашето здраве и повърхностите, върху които се прилагат.</li>
                <li className="list__item list__item--unordered">Дезинфекцираме всички “Червени точки” с натурален биоциден препарат.</li>
                <li className="list__item list__item--unordered">Ароматизираме дамаски и текстилни повърхности с цел неутрализиране на миризмите и оставяне на свеж, ненатрапчив аромат.</li>
            </ul>
        </section>
        <section className="section">
            <h1 className="title_1">Еко препарати</h1>
            <p className="paragraph">Настоящият ни асортимент от препарати притежава Европейски Еко сертификат. Това означава, че препаратите съдържат химични формули - максимално щадящи Вашето здраве и повърхностите, върху които ще бъдат използвани. Препаратите ни са изключително подходящи и за хора с астма или алергии. Повечето от продуктите са Веган и не съдържат животински деривати, също така и не са тествани върху животни.</p>
            <Image address="/file/image/preparation.jpg"/>
        </section>
        <section className="section">
            <h1 className="title_1">Дезинфекция</h1>
            <p className="paragraph">При всяко чистене екипът на Лимпио ще дезинфекцира щателно “Червените точки” във Вашия дом - брави, хладилници, мивки, кофи за отпадъци, осветителни ключове и др.Това са често пренебрегвани зони, които обаче според проучванията крият най-голям риск от пренасяне на бактерии и зарази.</p>
            <Image address="/file/image/disinfection.jpg"/>
        </section>
        <section className="section">
            <h1 className="title_1">Пречистване на въздуха</h1>
            <p className="paragraph">Ние разполагаме с портативен пречиствател за въздух с HEPA филтър от последно поколение, който гарантира елиминирането на бактерии, вируси и патогенни микроорганизми. Устройството е снабдено с йонизатор, който зарежда Вашия дом с отрицателни йони, които са благоприятни за човешкия организъм и подпомагат естествената имунна защита на тялото. Уредът притежава също и UV лампа, която допълнително редуцира количеството на опасните за здравето ни микроорганизми.</p>
            <Image address="/file/image/purification.jpg"/>
        </section>
        <section className="section">
            <h1 className="title_1">Ароматерапия</h1>
            <p className="paragraph">Лимпио използва ароматизиращи спрейове от оторизирани европейски доставчици, които ще освежат Вашия дом, ще оставят деликатен и приятен аромат и ще неутрализират силните миризми(готвено, цигари, домашни любимци).</p>
            <Image address="/file/image/aromatherapy.jpg"/>
        </section>
    </>
}
