export default function PageServiceBG() {
    return <>
        <section className="section">
            <h1 className="title_1">Абонаментно почистване</h1>
            <img className="image image--half" src="/file/image/cleaning-domestic.jpg"/>
            <ul className="list list--unordered">
                <li className="list__item list__item--unordered">избърсване на прах от повърхности;</li>
                <li className="list__item list__item--unordered">почистване на кухненски  шкафове (външно);</li>
                <li className="list__item list__item--unordered">почистване на кухненски плот, котлони, фурна (външно);</li>
                <li className="list__item list__item--unordered">избърсване на прах от декорации;</li>
                <li className="list__item list__item--unordered">измиване на санитарни помещения (тоалетна, мивка, огледала, под);</li>
                <li className="list__item list__item--unordered">прахосмучене;</li>
                <li className="list__item list__item--unordered">измиване на твърда подова настилка;</li>
                <li className="list__item list__item--unordered">изхвърляне на битов отпадък.</li>
                <li className="list__item list__item--unordered">всичко от услугата бутиково почистване</li>
            </ul>
            <p className="paragraph">За повече информация</p>
            <p className="paragraph">+359 895 738 848</p>
        </section>
        <section className="section">
            <h1 className="title_1">Основно почистване</h1>
            {/* <div className="container"> */}
                <img className="image image--half" src="/file/image/cleaning-general.jpg"/>
            {/* </div> */}
            <ul className="list list--unordered">
                <li className="list__item list__item--unordered">почистване на стъкла и стъклени повърхности;</li>
                <li className="list__item list__item--unordered">премахване на прах от мебели;</li>
                <li className="list__item list__item--unordered">почистване на врати и каси;</li>
                <li className="list__item list__item--unordered">почистване на радиатори;</li>
                <li className="list__item list__item--unordered">почистване на ключове и контакти;</li>
                <li className="list__item list__item--unordered">прахосмучене;</li>
                <li className="list__item list__item--unordered">измиване на твърда подова настилка;</li>
                <li className="list__item list__item--unordered">обезпрашаване;</li>
                <li className="list__item list__item--unordered">измиване на санитарни възли (стени, мивки, тоалетни, душ кабини, вани, декоративни стъкла);</li>
                <li className="list__item list__item--unordered">почистване на кухненски шкафове;</li>
                <li className="list__item list__item--unordered">почистване на гардероби;</li>
                <li className="list__item list__item--unordered">измиване на тераси;</li>
                <li className="list__item list__item--unordered">всичко от услугата бутиково почистване;</li>
            </ul>
            <p className="paragraph">За повече информация</p>
            <p className="paragraph">+359 895 738 848</p>
        </section>
        <section className="section">
            <h1 className="title_1">Общи условия</h1>
            <ul className="list list--unordered">
                <li className="list__item list__item--unordered">За повече информация, относно извършваните услуги, моля свържете се с наш консултант.</li>
                <li className="list__item list__item--unordered">Крайната цена и условията се определят единствено на място, при предварително заявен оглед.</li>
                <li className="list__item list__item--unordered">Обработват се заявки по време на празници и почивни дни на преференциални условия.</li>
                <li className="list__item list__item--unordered">Цените на услугите могат да варират в случай на утежнени условия.</li>
                <li className="list__item list__item--unordered">Услугата за пречистване на въздух се извършва по предварителна заявка.</li>
                <li className="list__item list__item--unordered">Количествени отстъпки за помещения над 200 м2.</li>
                <li className="list__item list__item--unordered">Услугите основно и абонаметно почистване се отнасят за всички видове помещения.</li>
                <li className="list__item list__item--unordered">Посочените цени са с включено ДДС.</li>
                <li className="list__item list__item--unordered">Минимална стойност на поръчка 100.00 лв.</li>
            </ul>
        </section>
    </>
}