export default function PageCookieEN() {
    return <>
        <section className="section">
            <h1 className="title_1">Cookie Policy</h1>
            <h2 className="title_2">Cookie Policy</h2>
            <h3 className="title_3">Use of cookies</h3>
            <p className="paragraph">Cookies are short text files or small packages of information that are stored by the Internet browser of your terminal device (computer, tablet, laptop or mobile phone) when you visit various sites and pages on the Internet. The main purpose of cookies is to make the user recognizable when he returns to the Website. Some "cookies" also have a more specific application, such as to remember the user's behavior on the site and facilitate the user's use of the Website. More information about how cookies work can be found on the Internet.</p>
            <h2 className="title_2">How are cookies used on this Website?</h2>
            <p className="paragraph">We use cookies on this Website primarily to facilitate the site's usability, improve its performance, and store information about user behavior. No personal data is stored in this process, i.e. through the "cookies" of the site, we cannot identify you as a person, which is why the Personal Data Protection Act does not apply to the collection of this information. The information collected by cookies is usually used in an aggregated form for the purpose of analyzing the user behavior of the Website, which allows us to improve the functionality of the site, user paths and the content used.</p>
            <h2 className="title_2">What cookies are used on this Website?</h2>
            <h3 className="title_3">Session cookies</h3>
            <p className="paragraph">This type of "cookies" makes it easier for you to use the site, as they store information temporarily, only within the session of the used browser. Typically, the information that is stored through them is what goods or services you have added to the cart, which pages of the site you have visited and how you arrived at certain information. These cookies do not collect information from your end device and are automatically deleted when you leave the Website or terminate your browser session.</p>
            <h3 className="title_3">Persistent cookies</h3>
            <p className="paragraph">They enable us to store specific browsing information, such as analyzing site visits, how you reached the Website, what pages you viewed, what options you selected, and where you went through this Website. Tracking this information enables us to make improvements to the Website, including correcting errors and expanding content. The storage period of this type of &quot;cookies&quot; varies according to their specific purpose.</p>
            <h3 className="title_3">Third Party "Cookies".</h3>
            <p className="paragraph">Our Website contains links to other sites or embedded content from other sites, for example from Facebook, YouTube, Twitter, Google+, LinkedIn, partner websites. It is possible that when you visit these sites or open their content, cookies from these websites are stored on your end device. It is these cookies that are defined as third-party cookies, and we have no control over the generation and management of these cookies. For this reason, we advise you to look for information about them and how they are managed on the respective third-party websites.</p>
            <h2 className="title_2">How can I manage this Website's use of cookies?</h2>
            <p className="paragraph">All browsers allow the management of cookies from a specially created folder on your browser. You can block the receipt of cookies, delete all or part of them, or set your preferences regarding the use of cookies before initiating a visit to our site. Please note that deleting or blocking cookies may adversely affect the functionality of our Website and therefore your user experience on it.</p>
            <h2 className="title_2">Turn off or block cookies</h2>
            <p className="paragraph">Controlling, turning off or blocking cookies is managed by your browser settings. Keep in mind that the complete prohibition of the use of all "cookies" may reflect on the functional presentation of the site, its efficiency and the accessibility of certain information.</p>
        </section>
    </>
}
