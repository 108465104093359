import { combineReducers } from 'redux'
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

import logger from './logger'
import searcher from './searcher'

const config = {
    key: `root`,
    storage,
    whitelist: [ logger ],
}

const reducer = combineReducers({
    logger,
    searcher,
})


export default persistReducer(config, reducer)
