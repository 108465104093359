import Image from 'component/image'

export default function PageBoutiqueEN() {
    return <>
        <header className="header">
            <h1 className="title">Boutique cleaning</h1>
            <p className="subtitle">I clean, therefore I exist…</p>
        </header>
        <section className="section">
            <h1 className="title_1">What is a boutique cleaning?</h1>
            <p className="paragraph">This is an entirely new concept that introduce a holistic approach to cleaning. The method was built taking into account the real needs of the modern man and aims to optimize his quality of life. This service will eliminate many of the sources of stress associated with labor-intensive home maintenance and transform your perception of a healthy environment, comfort and security.</p>
        </section>
        <section className="section">
            <h1 className="title_1">What makes us different?</h1>
            <ul className="list list--unordered">
                <li className="list__item list__item--unordered">Eco cleaning liquids, which are health safety and safety for the surfaces on which they are applied.</li>
                <li className="list__item list__item--unordered">We disinfect all "Red points" with a natural biocidal liquid.</li>
                <li className="list__item list__item--unordered">We purify the air with a professional machine, which contains seven different filters.</li>
                <li className="list__item list__item--unordered">We aromatize the upholstery, air and textile surfaces in order to neutralize odors and leave a fresh and relaxing aroma.</li>
            </ul>
        </section>
        <section className="section">
            <h1 className="title_1">Eco preparations</h1>
            <p className="paragraph">Our current set of cleaning liquids has a European Eco certificate. This means that the liquids are containing chemical formulas - maximally sparing your health and the surfaces on which they would be applied. The cleaning liquids are also extremely suitable for people with asthma or allergies. Most of the products are Vegan and do not contain animal derivatives, nor are they tested on animals.</p>
            <Image address="/file/image/preparation.jpg"/>
        </section>
        <section className="section">
            <h1 className="title_1">Disinfection</h1>
            <p className="paragraph">With each cleaning, the Limpio team will thoroughly disinfect the "Red points" in your home - locks, refrigerators, sinks, waste bins, light switches, etc. These are often neglected areas, which, however, according to studies, hide the greatest risk of transmitting bacteria, viruses and infections.</p>
            <Image address="/file/image/disinfection.jpg"/>
        </section>
        <section className="section">
            <h1 className="title_1">Air purification</h1>
            <p className="paragraph">We have a portable air purifier with a latest generation HEPA filter that ensures the elimination of bacteria, viruses and pathogenic microorganisms. The device is equipped with an ionizer that charges your home with negative ions that are beneficial to the human body and support the body's natural immune defenses. The device also has a UV lamp, which further reduces the amount of microorganisms dangerous to our health.</p>
            <Image address="/file/image/purification.jpg"/>
        </section>
        <section className="section">
            <h1 className="title_1">Aromatherapy</h1>
            <p className="paragraph">Limpio uses aroma sprays from authorized European suppliers, which will refresh your home, leave a delicate and pleasant aroma and neutralize strong odors (cooking, cigarettes, pets).</p>
            <Image address="/file/image/aromatherapy.jpg"/>
        </section>
    </>
}
