import Image from 'component/image'
import Contact from "component/contact"

export default function PageHomeBG() {
    return <>
        <section className="section">
            <p className="paragraph">Какво означава да е ЧИСТО? Това, което правим, за да поддържаме хигиенична среда, достатъчно ли е? Има ли скрити рискове от пренебрегване на системната дезинфекция и какви са те? Тези въпроси провокираха серия от идеи и начини за справяне с невидимите замърсители. Това беше и тригърът, който наложи създаването на една нова за България концепция в почистването.</p>
            <Image address="/file/image/result.jpg"/>
        </section>
        <Contact/>
    </>
}
