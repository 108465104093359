import Image from 'component/image'
import Contact from "component/contact"

export default function PageHomeEN() {
    return <>
        <section className="section">
            <Image address="/file/image/result.jpg"/>
            <p className="paragraph">What does it mean to be CLEAN? Is what we are doing to maintain a hygienic environment enough? Are there hidden risks to neglecting system disinfection, and what are they? These questions provoked a series of ideas and ways to deal with invisible pollutants. This was also the trigger that necessitated the creation of a new concept in cleaning for Bulgaria.</p>
        </section>
        <Contact/>
    </>
}
