export default function PageServiceEN() {
    return <>
        <section className="section">
            <h1 className="title_1">Domestic cleaning</h1>
            <img className="image image--half" src="/file/image/cleaning-domestic.jpg"/>
            <ul className="list list--unordered">
                <li className="list__item list__item--unordered">wiping dust from surfaces;</li>
                <li className="list__item list__item--unordered">cleaning kitchen cabinets (external);</li>
                <li className="list__item list__item--unordered">cleaning of kitchen counter, stoves, oven (external);</li>
                <li className="list__item list__item--unordered">dusting decorations;</li>
                <li className="list__item list__item--unordered">washing sanitary facilities (toilet, sink, mirrors, floor);</li>
                <li className="list__item list__item--unordered">vacuuming;</li>
                <li className="list__item list__item--unordered">washing hard floors;</li>
                <li className="list__item list__item--unordered">disposal of household waste.</li>
                <li className="list__item list__item--unordered">everything from a boutique cleaning service</li>
            </ul>
            <p className="paragraph">For more information</p>
            <p className="paragraph">+359 895 738 848</p>
        </section>
        <section className="section">
            <h1 className="title_1">General cleaning</h1>
            <img className="image image--half" src="/file/image/cleaning-general.jpg"/>
            <ul className="list list--unordered">
                <li className="list__item list__item--unordered">cleaning of glass, window frames, aluminum blinds;</li>
                <li className="list__item list__item--unordered">dust removal from furniture (indoor and outdoor);</li>
                <li className="list__item list__item--unordered">cleaning of doors and cash registers;</li>
                <li className="list__item list__item--unordered">cleaning radiators;</li>
                <li className="list__item list__item--unordered">cleaning of switches and sockets;</li>
                <li className="list__item list__item--unordered">vacuuming;</li>
                <li className="list__item list__item--unordered">washing hard floors;</li>
                <li className="list__item list__item--unordered">dust removal;</li>
                <li className="list__item list__item--unordered">washing of sanitary facilities (walls, sinks, toilets, showers, bathtubs, decorative glass);</li>
                <li className="list__item list__item--unordered">cleaning kitchen cabinets (interior and exterior);</li>
                <li className="list__item list__item--unordered">wardrobe cleaning (external);</li>
                <li className="list__item list__item--unordered">washing terraces;</li>
                <li className="list__item list__item--unordered">everything from a boutique cleaning service</li>
            </ul>
            <p className="paragraph">For more information</p>
            <p className="paragraph">+359 895 738 848</p>
        </section>
        <section className="section">
            <h1 className="title_1">Terms and conditions</h1>
            <ul className="list list--unordered">
                <li className="list__item list__item--unordered">For more information about the services provided, please contact our consultant.</li>
                <li className="list__item list__item--unordered">The final price and conditions are determined only on site, upon a pre-ordered inspection.</li>
                <li className="list__item list__item--unordered">Requests are processed during holidays and weekends on preferential terms.</li>
                <li className="list__item list__item--unordered">Service prices may vary in case of aggravated conditions.</li>
                <li className="list__item list__item--unordered">Quantity discounts for premises over 200 m2.</li>
                <li className="list__item list__item--unordered">Domestic and general cleaning services apply to all types of premises.</li>
                <li className="list__item list__item--unordered">The indicated prices include VAT.</li>
                <li className="list__item list__item--unordered">Minimum order value BGN 100.00.</li>
            </ul>
        </section>
    </>
}