import { useCallback, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

export default function Reroute({ address }) {
    const navigate = useNavigate()

    const reroute = useCallback(async _ => {
        navigate(address)
    }, [ navigate ])

    useEffect(_ => {
        void reroute()
    }, [ reroute ])

    return <></>
}