import { useState } from 'react'
import { useParams } from "react-router-dom"

import languages from 'utility/language'

import Reroute from "component/reroute"

import Header from 'header'
import Main from 'main'
import Footer from 'footer'

export default function Translate() {
    const { language } = useParams()
    const [ toggle, update_toggle ] = useState(false)
    return !languages.includes(language)
        ? <Reroute address="/en/home"/>
        : <>
            <Header toggle={ toggle } update_toggle={ update_toggle }/>
            <Main/>
            <Footer/>
        </>
}
